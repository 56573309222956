import React from 'react'
import { Container } from 'react-bootstrap'

function PageTitle(props) {
  return (
    <div className={`page-title ${props.mini} text-center`}>
      <Container className='w-100 ma'>
        <h1 className="title-big c-dark p-3 ad">{props.title}</h1>
        <h3 className='title-medium c-medium px-md-3 w-60 ma au'>{props.desc}</h3>
        {props.children}
      </Container>
    </div>
  );
}

export default PageTitle
