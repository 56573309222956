import React from 'react'
import { Container } from 'react-bootstrap'

import Nav from '../parts/Nav'
import Footer from '../parts/Footer'

import PageTitle from '../components/PageTitle'

function Terms() {
  return (
    <>
      <Nav />
      <PageTitle
        mini="mini"
        title="USER AGREEMENT"
        desc="Last updated: May 18, 2023">
      </PageTitle>
      <div className="s-100 m-hide"></div>
      <div className="s-100 m-hide"></div>
      <div className="s-40 m-hide"></div>
      <Container>
        <div className="section-page">
          <div className="container mt-2xl mb-2xl page">
            <p>
            <strong>Welcome to Deadline Expert</strong>
            </p>
            <p>
            <strong>About Your Account</strong>
            </p>
            <p>
            This User Agreement is a contract between you and CLEVERVOL INC governing your use of the Deadline Expert account and other services provided by CLEVERVOL INC. By opening and using a Deadline Expert account, you agree to comply with all the terms and conditions of this User Agreement, including the Privacy Policy pages and any future changes.
            </p>
            <p>
            This User Agreement of CLEVERVOL INC ("we," "our," "us") provides information on how we provide access to our mobile application (referred to as "the Services," "we," "us," "our") and all users (whether private individuals or representatives of legal entities, directors, public figures, employees, etc.).
            </p>
            <p>
            The primary purpose of this Agreement is to engage in joint activities through step-by-step algorithms and derive mutual benefits from cooperation, exclusively for your advantage:
            </p>
            <ul>

            <li>Obtain additional GRP (for information on what GRP is, please see the <a href="https://en.wikipedia.org/wiki/Gross_rating_point">provided link</a>).</li>

            <li>Identify and provide more consistent and understandable access to online or print media, such as press outlets and bloggers.</li>

            <li>Implement time management practices, content management, and other experiences related to operating various information sites or subprograms without limitations.
            </li>
            </ul>
            <p>
            Access to the Deadline Expert mobile application is provided on an "as is" basis. We do not knowingly collect or sell personal data from children under the age of 18.
            </p>
            <p>
            The content of this document was created considering the practices of our colleagues and relevant legislation. This Agreement serves as the foundation for any future agreements that may be entered into.
            </p>
            <p>
            Please read the entire document.
            </p>
            <p>
            <strong>1. Who is the User?</strong>
            </p>
            <p>1.1. Any private individual or representative of a legal entity, director, public figure, employee, etc., who has visited at least one of our promo pages, including web pages, without prior registration and authorization, is considered a visitor.</p>
            <p>1.2 A visitor who registers and authorizes using their personal data becomes a User.</p>
            <p>1.3 During registration, the User can choose one of two work statuses within the program:
              <ul>
                <li>"Journalist": Allows the User to create posts (text, photo, video materials) related to their professional activities, fulfill professional duties, such as studying the market or researching specific topics.</li>

                <li>"Expert": Allows the User to exchange text, photo, and video messages, showcase their own capabilities or professional expertise, explore other possibilities or ideas, such as searching for information partners.
                </li>
              </ul>
            </p>
            <p>
            Changing the status is possible during further collaboration.
            </p>
            <p>
            1.4. The User is granted access to advanced features.
            </p>
            <p>
            1.5. To utilize these features, the User must agree to this agreement. If the User does not accept the terms of this agreement, they are not authorized to use the program.
            </p>
            <p>
            <strong>2. How to Work with Text (Photo, Video) Materials</strong>
            </p>
            <p>
            2.1. Registered visitors (Users) of Deadline Expert have the opportunity to share and receive information in the form of photo and video content. They can also comment on the provided information, express their own opinions, arguments, and observations, engage in online communication or negotiations to achieve their desired outcomes. All of this is facilitated through the program's automatic mathematical algorithms.
            </p>
            <p>
            2.2. User privileges in our mobile application include:
            </p>
            <ul>

            <li>Creating posts to express opinions and observations.</li>

            <li>Commenting on posts, sharing, and utilizing other files and documents (text, photo, video).</li>

            <li>Using Deadline Expert at their discretion to solve communication tasks that align with the core activities of the program.</li>

            <li>Requesting assistance when needed.
            </li>
            </ul>
            <p>
            2.3. Unless otherwise noted, CLEVERVOL INC owns the copyright to the materials and application code.
            </p>
            <p>
            2.4. Each officially registered trademark, slogan, symbol, and name is legally owned by its respective owner. The use of symbols (R) and/or ™ in the materials is not mandatory.
            </p>
            <p>
            2.5. The User is aware of and agrees to the Privacy Policy, which is an integral part of this Agreement. It explains how personal data is stored and used.
            </p>
            <p>
            3. How to Properly Comment and Create Posts
            </p>
            <p>
            3.1. All users of Deadline Expert are obliged to adhere to the principles outlined here when creating text posts or comments.
            </p>
            <p>
            3.2. All users share equal responsibility for posting information and using Deadline Expert in accordance with the terms of the Agreement and applicable legislation. It is important to note that the failure to familiarize oneself with the restrictions and rules does not excuse non-compliance or evasion.
            </p>
            <p>
            3.3. The following actions are strictly prohibited:
            </p>
            <ul>

            <li>Providing knowingly false information about oneself with the intention to deceive other users or engage in intentionally misleading communication with journalists or experts. This includes creating accounts with User names that imitate official titles (administrator, moderator, etc.). CLEVERVOL INC strives to ensure that user data is as complete and accurate as possible.</li>

            <li>Making calls for a violent change of the constitutional order, seizure of state power, or alteration of state borders in any form (e.g., press releases, requests, or answers).</li>

            <li>Encouraging disorder, pogroms, arson, seizure or destruction of property, or deliberately advocating for aggression or the prolongation of any military conflict.</li>

            <li>Insulting other users of Deadline Expert or individuals such as experts, entrepreneurs, politicians, officials, media representatives, or any other persons based on national, racial, or religious attributes, or engaging in chauvinistic behavior.</li>

            <li>Publishing or sending offensive, pornographic, erotic, or sexual statements in messages. This also includes posting photos and videos of erotic or pornographic content.</li>

            <li>Displaying offensive behavior (statements, messages, correspondence) towards users or administrators of CLEVERVOL INC.</li>

            <li>Distributing press releases, posts, or reactions solely for the purpose of direct advertising or commercial offers. Additionally, publishing messages that lack informative content (including announcements of purchase and sale) and are unrelated to the work of journalists or bloggers. Please contact us in advance at <a href="mailto:contact@deadline.com.ua">contact@deadline.com.ua</a> for advertising discussions.</li>

            <li>Engaging in flooding or multiple copying and sending of identical press releases, posts, or messages, as well as flooding chat correspondence.</li>

            <li>Interfering with the functioning of Deadline Expert (including attempts to hack or steal data) or attempting to make changes to the software code of the product.</li>

            <li>Engaging in any other actions prohibited by law.
            </li>
            </ul>
            <p>
            <strong>4. Responsibility for Posting Information</strong>
            </p>
            <p>
            4.1. Deadline Expert provides its users with a platform for communication, including chat and commenting systems. Every user has the freedom to express their opinions on any topic.
            </p>
            <p>
            4.2. The author of any information posted in sections such as "My pitches," "My profile," "My chats," and comments is solely responsible for the accuracy and value of that content. These sections may undergo pre-moderation and post-moderation, which involves verification of messages by a moderator authorized by the Administration of CLEVERVOL INC before or after publication.
            </p>
            <p>
            4.3. If a user comes across information that harms someone's interests, it is recommended to personally contact the author of that information and attempt to resolve the dispute within the capabilities of Deadline Expert. The user can also seek assistance from the Administration, but after making an independent effort.
            </p>
            <p>
            4.4. In the event of a user's non-compliance with the clauses of this Agreement or their unwillingness/inability to adhere to the rules, the Administration of CLEVERVOL INC reserves the right to suspend the user's Agreement.
            </p>
            <p>
            4.5. Users who violate the terms of this Agreement may have their profiles blocked, leading to full or partial loss of access to the application and its functions.
            </p>
            <p>
            4.6. If there is a need to appeal the decisions made by a moderator, users are allowed to contact the Administration of CLEVERVOL INC, provided that their position is clearly justified.
            </p>
            <p>
            <strong>5. Confidentiality Agreement</strong>
            </p>
            <p>
            5.1. The collection, use, and disclosure of information that qualifies as confidential is governed by this clause of the Agreement, which covers both personalized and non-personalized confidential information.
            </p>
            <p>
            5.2. CLEVERVOL INC makes every effort to ensure the completeness and accuracy of user data. The collection, storage, use, processing, and disclosure of information received by the application's administration from private individuals (or representatives of legal entities, directors, public figures, employees, etc.) or visitors and users, including the storage of registration form data, is conducted in accordance with the applicable legislation and explained in the Privacy Policy. By agreeing to the terms of this Agreement, the user is aware of and consents to the collection and processing of their personal data within the scope and purpose specified in the Agreement.
            </p>
            <p>
            <strong>6. Understanding Our Data Handling Practices</strong>
            </p>
            <p>
            This section provides an overview of our policies related to the acquisition, use, and sharing of data, along with the options available to you concerning your data.
            </p>
            <p>
            We collect, process, and store data to enhance our operations, in line with the European Union's requirements. We clarify these terms of data usage, considering some of our users may reside in the EU.
            </p>
            <p>
            6.1. Gathering and Utilization of Information
            </p>
            <p>
            We acquire different types of information for various reasons to offer and enhance our service. The data we collect includes:
            </p>
            <p>
            •	Personal Data: When using Deadline Expert, we may request you to provide specific personal information that can be used to reach out to you. This information may include:
            </p>
            <ul>

            <li>Email address</li>

            <li>First and last name</li>

            <li>Phone number</li>

            <li>Place of employment (general information)</li>

            <li>Cookies and usage data</li>

            <li>Other relevant details
            </li>
            </ul>
            <p>

            </p>
            <p>
            •	Usage Data: This may encompass details such as your IP address, browser type, browser version, the time and date of your visit, unique device identifiers, and other diagnostic data.
            </p>
            <p>
            •	Tracking and Cookie Data: We employ cookies and similar technologies to track Deadline Expert activities and <a href="https://clevervol.com/">https://clevervol.com/</a> to gather and hold particular information. Cookies are small data files that might contain an anonymous unique identifier. They are typically dispatched to your web browser from websites and saved on your device. They enable websites to operate more swiftly and effectively, providing the site owners with valuable information, including preferences for advertising and targeting purposes. The term "cookie" is used in this agreement to cover all analogous technologies, like web beacons, SDKs, log files, and pixel tags.
            </p>
            <p>
            6.2. Data Transfer
            </p>
            <p>
            Information, including the details in the "My Profile" section, may be transferred for storage to computers located outside your country where the data protection laws may differ from those of your own country.
            </p>
            <p>
            Your agreement to this section implies consent to such a transfer.
            </p>
            <p>
            6.3. Data Security
            </p>
            <p>
            We place a high value on data security, but it's important to remember that no mode of transmission over the internet or electronic storage method is entirely secure. While we endeavor to use commercially viable means to protect your data, we cannot assure its absolute security.
            </p>
            <p>
            6.4. Partnership with Google Analytics
            </p>
            <p>
            Google Analytics is a web analytics service provided by Google that tracks and reports website traffic. Google uses the gathered data to monitor and evaluate performance. This third-party analytics service may use the collected data to contextualize and personalize its own ad network's ads. You can opt-out from having your activity on our service available to Google Analytics by installing the Google Analytics opt-out browser add-on. This add-on prevents Google Analytics JavaScript (ga.js, analytics.js, and dc.js) from sharing your visit information with Google Analytics. For more details on Google's privacy practices, please <a href="https://policies.google.com/privacy?hl=uk">visit their website and follow</a> the provided instructions.
            </p>
            <p>
            6.5. Collaboration with Other Service Providers
            </p>
            <p>
            We may employ third-party services to enhance the functionality of Deadline Expert. These third parties are only granted access to your data to perform these tasks on our behalf and are obligated not to disclose it. Additionally, we may need to share your personal data under the following circumstances:
            </p>
            <p>

            </p>
            <p>
            •	Marketing Campaigns (including personalization and advertising): We gather information and may transfer it to third-party services to ensure you receive specific communications, such as newsletters via email or to a designated phone number. For instance, we use Mixpanel (www.mixpanel.com) to analyze visitor and user behavior.
            </p>
            <p>
            •	Organizational Processes: We may share or transfer your information in relation to, or during negotiations of, any merger, sale of company assets, financing, or acquisition of all or a portion of our business by another company.
            </p>
            <p>
            The data we collect and process is transmitted in encrypted form. This ensures that the information remains secure during transmission. However, when it comes to sharing the data with third parties for marketing purposes, we utilize a different format to prevent any unauthorized access or misuse.
            </p>
            <p>
            6.6. Objectives of collecting and storing user data
            </p>
            <p>
            The primary objective behind collecting and storing user data is to fulfill the terms of this Agreement as administered by CLEVERVOL INC. These objectives aim to provide users with:
            </p>
            <ul>

            <li>Support and consultations regarding the Services</li>

            <li>Notifications regarding any changes in the Services</li>

            <li>Invitations to participate in interactive functions</li>

            <li>Analysis of valuable information for continuous improvement</li>

            <li>Quality control of Service provision</li>

            <li>Identification, prevention, and resolution of technical issues.
            </li>
            </ul>
            <p>
            <strong>7. Premium Features:</strong>
            </p>
            <p>
            7.1. Users of the Deadline Expert application have the option to purchase additional services. A list of our premium offerings can be found on a dedicated page of our website <a href="https://clevervol.com/pricing/">https://clevervol.com/pricing/</a>, which is conveniently illustrated for your reference.
            </p>
            <p>
            7.2. All prices listed on our website are displayed in US dollars and are inclusive of all taxes (including value-added tax). For Ukrainian customers, an automatic currency conversion will be implemented based on the exchange rate set by the National Bank of Ukraine at the time of payment.
            </p>
            <p>
            7.3. To access our premium services, users must make a full upfront payment for the selected service, using the payment methods available on our website.
            </p>
            <p>
            7.4. Upon ordering and paying for our services, a user is granted the status of a "Business User".
            </p>
            <p>
            7.5. Our commercial service is set to launch in March 2024, or earlier if available. As a "Business User", you are entitled to request a refund for any undelivered services, should CLEVERVOL INC (or any other service provider chosen by CLEVERVOL INC) fail to fulfill the terms of the Agreement.
            </p>
            <p>
            7.6. Payment processing services are facilitated by STRIPE INC (www.stripe.com).
            </p>
            <p>
            <strong>8. Final Provisions:</strong>
            </p>
            <p>
            8.1. By completing the Deadline Expert program registration form, users indicate their acceptance of this User Agreement. Regardless of the actual usage of the program, all users must agree to the terms of the Agreement. Utilizing any feature of Deadline Expert is considered an acceptance of the Agreement's terms from the moment of registration.
            </p>
            <p>
            8.2. The management of CLEVERVOL INC operates in compliance with all relevant legislation. All official notifications, disagreements, conflicts, and complaints will be addressed in line with this principle.
            </p>
            <p>
            8.3. The CLEVERVOL INC administration, including all representatives, owners, and managers, disclaims any liability for actions or inactions resulting in direct or indirect damages or lost profits to users, visitors, or third parties, arising from the use or non-use of the program.
            </p>
            <p>
            8.4. By agreeing to this User Agreement, users accept responsibility for any breach of the Agreement’s terms and for any resulting consequences, including any material or other damage caused to the administration of CLEVERVOL INC due to violations of this Agreement.
            </p>
            <p>
            8.5. Measures necessary to secure internet access and the procurement or installation of suitable hardware are outside the scope of this Agreement and should be arranged by the user.
            </p>
            <p>
            8.6. The terms of this document may be changed with or without prior notice.
            </p>
            <p>
            8.7. If you have any questions or comments regarding other rights or opportunities, please reach out to us via email at <a href="mailto:contact@deadline.com.ua">contact@deadline.com.ua</a>.
            </p>
            <p>
            8.8. For all other queries, please email us at <a href="mailto:contact@deadline.com.ua">contact@deadline.com.ua</a>.
            </p>
            <p>
            <strong>We appreciate your collaboration.</strong>
            </p>

          </div>
        </div>
      </Container>
      <div className="s-100"></div>
      <Footer />
    </>
  )
}
export default Terms
