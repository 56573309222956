import React from 'react'
import { Container } from 'react-bootstrap'
import { Helmet } from 'react-helmet'

import Nav from '../parts/Nav'
import Footer from '../parts/Footer'


function NotFound() {

  return (
    <>
      <Helmet>
        <title>404 - Page Not Found</title>
      </Helmet>
      <Nav />
      <div className={`page-title text-center`}>
        <Container>
          <div className="section-page">
            <div className="container mt-2xl mb-2xl page">
            <h1 className="title-big c-dark p-3 ad">404 - Page Not Found</h1>
            <h3 className='title-medium c-medium px-md-3 w-60 ma au'>Oops! Looks like the page you were looking for isn't here.</h3>
          </div></div>
        </Container>
      </div>
      <div className="s-100"></div>
      <div className="s-100"></div>
      <div className="s-100"></div>
      <div className="s-100"></div>
      <div className="s-100"></div>
      <div className="s-40"></div>
      <div className="s-100"></div>
      <div className="s-100"></div>
      <Footer />
    </>
  )
}
export default NotFound
