import React  from 'react'
import { Link } from 'react-router-dom'
import { Container } from 'react-bootstrap'

import { SliderTop } from '../components/sliders/SliderTop'
import { SliderRevs } from '../components/sliders/SliderRevs'

import Nav from '../parts/Nav'
import Footer from '../parts/Footer'

import PageTitle from '../components/PageTitle'

import dios from '../assets/img/download/ios-download.png'
import dgp from '../assets/img/download/gp-download.png'

import feat1 from '../assets/img/features/feat_001.png'
import feat2 from '../assets/img/features/feat_002.png'
import feat3 from '../assets/img/features/feat_003.png'

import screens from '../assets/img/screens/EN.png'


const ios = '/'
const and = '/'

function Home() {

  return (
    <>
      <Nav />
      <PageTitle
        title="Get Your Story To The World With Deadline Expert"
        desc="Deadline Expert is a mobile app for journalists to get their story out there. It's easy and free for them, and you can create a reward with the journalist too">
        <div className="flex w-100 aic jcc au">
          <Link to={ios} className='mx-md-3'><img src={dios} alt="Download for iOS" className='bigger-btn' /></Link>
          <Link to={and} className='mx-md-3'><img src={dgp} alt="Download for Android" className='bigger-btn' /></Link>
        </div>
        <div className="s-30"></div>
        <p className="title-mini c-blue au">14 day free trial • No credit card required</p>
        <div className="s-30"></div>
        <p className="title-small c-card au">Show credibility with social proof. Refers of press coverage or biggest customers</p>
        <div className="s-30"></div>
        <div><SliderTop /></div>
        <div className="s-100 d-lg-none"></div>
        <div className="s-100 d-lg-none"></div>
        <div className="flex w-100 aic jcc mt-3">
          <img className='screens w-70 ma' src={screens} alt="Screens" />
        </div>
      </PageTitle>
      <div className="s-100 m-hide"></div>
      <div className="s-100 m-hide"></div>
      <div className="s-100"></div>
      <div className="s-100"></div>
      <div className="s-100 d-lg-none"></div>
      <div className="s-100 d-lg-none"></div>
      <div className="s-100 d-lg-none"></div>
      <div className="s-100 d-lg-none"></div>
      <div className="s-100 d-lg-none"></div>
      <div className="s-100 d-lg-none"></div>
      <Container>
        <div id="about">
          <h1 className='title-large text-center'>The easiest way to reach journalists</h1>
          <div className="s-10 m-hide"></div>
          <h3 className='title-medium text-center w-80'>Deadline Expert is an app for journalists seeking different origins, opinions or cases that will helpful by creating useful stories</h3>
          <div className="row">
            <div className="col-md-6 flex h-80 aic a-hide">
              <div className="mx-5">
              <img src={feat1} alt="Get discovered by mobile" />
              </div>
            </div>
            <div className="col-md-6 flex h-80 aic">
              <div className="mx-5">
              <h5 className="title-mini c-blue">#1 FEATURE</h5>
              <div className="s-10"></div>
              <h3 className="title-large c-dark">Get discovered by mobile</h3>
              <div className="s-10"></div>
              <p className="title-small c-dark">The app allows for instant communication, it doesn't make sense to waste time on sending email messages and waiting for a response. Immediacy and easy-of-use are what make Deadline Expert so unique and clear among people</p>
              </div>
            </div>
            <div className="col-md-6 flex h-80 aic m-hide">
              <div className="mx-5">
              <img src={feat1} alt="Get discovered by mobile" />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 flex h-80 aic">
              <div className="mx-5">
              <img src={feat2} alt="Personal content strategy" />
              </div>
            </div>
            <div className="col-md-6 flex h-80 aic">
              <div className="mx-5">
              <h5 className="title-mini c-blue">#2 FEATURE</h5>
              <div className="s-10"></div>
              <h3 className="title-large c-dark">Personal content strategy</h3>
              <div className="s-10"></div>
              <p className="title-small c-dark">Create professional content strategy and find the right people by theme, activity or similar expertise. You can also create your own lists and save mentions for later cooperation</p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 flex h-80 aic a-hide">
              <div className="mx-5">
              <img src={feat3} alt="Motion yourself and make your brand" />
              </div>
            </div>
            <div className="col-md-6 flex h-80 aic">
              <div className="mx-5">
              <h5 className="title-mini c-blue">#3 FEATURE</h5>
              <div className="s-10"></div>
              <h3 className="title-large c-dark">Motion yourself and make your brand</h3>
              <div className="s-10"></div>
              <p className="title-small c-dark">Deadline Expert connects you to journalists and media outlets. It lets you pitch quickly, review PR campaigns and post press releases. You can also share your company's latest trends and updates with the world, market your business locally, or promote a partnership. With us, you're always in contact with journalists who want to work with you</p>
              </div>
            </div>
            <div className="col-md-6 flex h-80 aic m-hide">
              <div className="mx-5">
              <img src={feat3} alt="Motion yourself and make your brand" />
              </div>
            </div>
          </div>
        </div>
      </Container>
      <div className="s-100"></div>
      <Container>
        <h1 className='title-large text-center mb-5 pb-md-5'>Case study</h1>
        <div className="row">
          <SliderRevs />
        </div>
      <div className="s-100"></div>
      <div className="s-100"></div>
      </Container>
      <div className='cta py-5'>
        <div className="s-40"></div>
        <h2 className="title-large-cta c-white w-60 mx-auto text-center">The Right Expertise, Delivered</h2>
        <div className="s-40"></div>
        <div className="flex w-100 aic jcc">
          <Link to={ios} className='mx-3 mx-xs-4'><img src={dios} alt="Download for iOS" className='smallest-btn one' /></Link>
          <Link to={and} className='mx-3 mx-xs-4'><img src={dgp} alt="Download for Android" className='smallest-btn two' /></Link>
        </div>
        <div className="s-20"></div>
        <p className="c-lblue text-center">14 day free trial • No credit card required</p>
        <div className="s-40"></div>
      </div>
      <Footer />
    </>
  )}

export default Home
