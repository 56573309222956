import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { Container } from 'react-bootstrap'
import Logo from '../assets/img/png/logo_clv.png'
import UA from '../assets/img/lang/ua.svg'


function Nav() {

  const [collapse, setCollapse] = useState('collapse')
  const [opened, setOpened] = useState(false)

  return (
    <nav className="navbar navbar-expand-lg navbar-dark fixed-top">
      <Container>
        <div className="flex jcsb w-100">
          <div>
            <h1 className='site-title'>
              <Link className="navbar-brand flex aic" to="/">
                <img src={Logo} alt="logo" width={52} height={52} className='my-2' />
                <p className='pt-md-3 c-card px-3'>Clevervol</p>
              </Link>
            </h1>
          </div>
          <div>
            <div className="flex aic jcsb">
              <Link className="nav-link c-card title-small px-md-3 mt-1 m-show" to="/ua/"
                onClick={() => {
                  setOpened(!opened)
                  opened ? setCollapse('collapse') : setCollapse('collapsed')
                }}>
                  <img src={UA} width={28} alt="Ukranian" />
              </Link>
              <button
                className="navbar-toggler"
                type="button"
                data-toggle="collapse"
                data-target="#navbarNav"
                aria-controls="navbarNav"
                aria-expanded={opened} aria-label="Toggle navigation"
                onClick={() => {
                  setOpened(!opened)
                  opened ? setCollapse('collapse') : setCollapse('collapsed')
                }}
              >
                <span className="navbar-toggler-icon"></span>
              </button>
            </div>
            <div className={`navbar-collapse ${collapse}`} id="navbarNav">
              <ul className="navbar-nav navbar-left py-md-3">
                <li className="nav-item">
                  <Link className="nav-link c-card title-small px-md-3 mt-1" to="/"
                    onClick={() => {
                      setOpened(!opened)
                      opened ? setCollapse('collapse') : setCollapse('collapsed')
                    }}>About</Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link c-card title-small px-md-3 mt-1" to="/pricing"
                    onClick={() => {
                      setOpened(!opened)
                      opened ? setCollapse('collapse') : setCollapse('collapsed')
                    }}>Pricing</Link>
                </li>
                <li className="nav-item m-hide">
                  <Link className="nav-link c-card title-small px-md-3 mt-1" to="/ua/"
                    onClick={() => {
                      setOpened(!opened)
                      opened ? setCollapse('collapse') : setCollapse('collapsed')
                    }}>
                      <img src={UA} width={28} alt="Ukranian" />
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </Container>
    </nav>
  );
}

export default Nav;
