import rev1 from '../../assets/img/reviews/r001.png'
import rev2 from '../../assets/img/reviews/r002.png'
import rev3 from '../../assets/img/reviews/r003.png'
import rev4 from '../../assets/img/reviews/r004.png'
import rev5 from '../../assets/img/reviews/r005.png'
import rev6 from '../../assets/img/reviews/r006.png'
import rev7 from '../../assets/img/reviews/r007.png'
import rev8 from '../../assets/img/reviews/r008.png'
import rev9 from '../../assets/img/reviews/r009.png'
import rev10 from '../../assets/img/reviews/r010.png'
import rev11 from '../../assets/img/reviews/r011.png'
import rev12 from '../../assets/img/reviews/r012.png'
import rev13 from '../../assets/img/reviews/r013.png'
import rev14 from '../../assets/img/reviews/r014.png'

const rev = [
  {
    id: 1,
    photo: rev1,
    name: 'Ірина Настич',
    position: 'Редактор',
    company: 'Property Times',
    text: 'Хочу подякувати експертам Deadline за те, що знаходите час відповідати на каверзні запитання, не спите ночами, щоб встигнути дати коментар на вчора!'
  },
  {
    id: 2,
    photo: rev2,
    name: 'Пазиченко Світлана',
    position: 'Pr-менеджер',
    company: 'ADS Legal Group',
    text: 'Шановні експерти, наповнюйте рубрику Deadline «Прес-реліз» матеріалами! Журналісти переглядають новини, оскільки це може виручати. Висловлюйтесь про вузькі теми, розказуйте про цікаве! Чат – для додаткових питань.'
  },
  {
    id: 3,
    photo: rev3,
    name: 'Ліна Кущ',
    position: 'Перша секретар',
    company: 'НСЖУ',
    text: 'Deadline - перший проект по медіа-кетчінгу (media catching) в Україні. Перевірені користувачі і зрозумілий підхід до підготовки статей - це те, що назавжди створює довіру. Колосальний потенціал для медіа!'
  },
  {
    id: 4,
    photo: rev4,
    name: 'Катерина Балановська',
    position: 'Лектор, бізнес-тренер',
    company: 'Visotsky Consulting',
    text: 'Це крутий портал! На Deadline є можливість відповідати на запити журналістів та знайомитись з ними. Раніше піарники стукалися для статті або коментаря в «зачинені двері»!'
  },
  {
    id: 5,
    photo: rev5,
    name: 'Олена Мазура',
    position: 'Бізнес-тренер, коуч',
    company: 'Підприємець',
    text: 'Друзі! Deadline - реальна економія часу на пошуки один одного. За 4 тижні вийшло 5 статей у ЗМІ. Відмінна довгострокова ідея, круто втілена в життя!'
  },
  {
    id: 6,
    photo: rev6,
    name: 'Євген Романенко',
    position: 'Президент ВАДНД',
    company: 'Проректор МАУП',
    text: 'Набагато більше цікавих думок на Deadline! Отримувати фактуру текстом чи аудіо швидше, ніж спілкуватися телефоном або проводити особисті зустрічі. Співпрацюйте!'
  },
  {
    id: 7,
    photo: rev7,
    name: 'Желдак Тімур',
    position: 'Головний редактор',
    company: 'Dniprograd',
    text: 'Набагато більше цікавих думок на Deadline! Отримувати фактуру текстом чи аудіо швидше, ніж спілкуватися телефоном або проводити особисті зустрічі. Співпрацюйте!'
  },
  {
    id: 8,
    photo: rev8,
    name: 'Сергій Наумов',
    position: 'Шеф-редактор',
    company: 'Баланс-Агро',
    text: 'На Deadline є підтримка користувачів, яка допомагає кожному ЗМІ успішно складати плани для матеріалів. Для сотень аналітичних видань України - це надійна перевага!'
  },
  {
    id: 9,
    photo: rev9,
    name: 'Ольга Германова',
    position: 'Експерт з податків',
    company: 'Верховна Рада',
    text: 'Чим більше журналістів отримуватимуть якісні коментарі, тим якіснішою ставатиме українська журналістика, а відтак і проінформованість громадян.'
  },
  {
    id: 10,
    photo: rev10,
    name: 'Катерина Буяльська',
    position: 'Заступниця редактора',
    company: 'Na Chasi',
    text: 'Нарешті в одному місці можна зібрати необхідну інформацію і ми перестанемо говорити про кризу експертів в Україні.'
  },
  {
    id: 11,
    photo: rev11,
    name: 'Анна Гончаренко',
    position: 'Кореспондент',
    company: 'NewsOne',
    text: 'Реакція миттєва, мене зкомуніцирували з трьома чудовими експертами. Співпрацювати зручно, комфортно і надійно. Дякую!'
  },
  {
    id: 12,
    photo: rev12,
    name: 'Юрій Григоренко',
    position: 'Економічний журналіст',
    company: '112.ua',
    text: 'Без перебільшення можу сказати, що подібної платформи дуже не вистачало як українським журналістам, так і експертам з піарниками.'
  },
  {
    id: 13,
    photo: rev13,
    name: 'Алеся Татар',
    position: 'Ведуча новин',
    company: '7 Канал',
    text: 'Дуже зручна платформа для пошуку спікерів на будь-які теми! Колеги-журналісти, рекомендую!'
  },
  {
    id: 14,
    photo: rev14,
    name: 'Григорий Перерва',
    position: 'Генеральний директор',
    company: 'Євро-Рейтинг',
    text: 'Створення ресурсу Deadlinе сприяло полегшенню життя компетентних експертів і має допомогти їм вийти на контакт з великим числом журналістів.'
  }
]
export default rev
