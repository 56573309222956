import React from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import './styles/custom.sass'

import Home from './pages/Home'
import Privacy from './pages/Privacy'
import Pricing from './pages/Pricing'
import Terms from './pages/Terms'
import NotFound from './pages/NotFound'

import HomeUA from './pages/HomeUA'
import PricingUA from './pages/PricingUA'
import PrivacyUA from './pages/PrivacyUA'
import TermsUA from './pages/TermsUA'

function App() {


  return (
    <BrowserRouter>
      <div className="app">
          <div className="bg-all">
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/pricing" element={<Pricing />} />
              <Route path="/privacy" element={<Privacy />} />
              <Route path="/user-agreement" element={<Terms />} />
              <Route path='*' element={<NotFound />} />

              <Route path="/ua/" element={<HomeUA />} />
              <Route path="/ua/pricing" element={<PricingUA />} />
              <Route path="/ua/privacy" element={<PrivacyUA />} />
              <Route path="/ua/user-agreement" element={<TermsUA />} />
            </Routes>
          </div>
      </div>
    </BrowserRouter>
  )
}

export default App
