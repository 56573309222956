import React from 'react'
import { Container } from 'react-bootstrap'

import Nav from '../parts/Nav'
import Footer from '../parts/Footer'

import PageTitle from '../components/PageTitle'

function Privacy() {
  return (
    <>
      <Nav />
      <PageTitle
        mini="mini"
        title="PRIVACY POLICY"
        desc="Last updated on May 11, 2023">
      </PageTitle>
      <div className="s-100 m-hide"></div>
      <div className="s-100 m-hide"></div>
      <div className="s-40 m-hide"></div>
      <Container>
        <div className="section-page">
          <div className="container mt-2xl mb-2xl page">
            <p>
            The privacy policy of CLEVERVOL INC ("we", "our", “us”) contains information about how and why we process your personal data (user data) when you use our services ("<strong>Services</strong>"), in particular, when you use our application <strong>Deadline Expert</strong>, which contains a link to this Privacy Policy ("Services"); interact with us in other ways, including interactions on any sales, marketing, and administrative issues.
            </p>
            <p>
              "Personal data" means any information relating to an identified or identifiable natural person (“data subject”); an identifiable natural person is one who can be identified, directly or indirectly, in particular by an identifier such as a name, an identification number, location data, an online identifier, or by one or more factors that determine the physical, physiological, genetic, mental, economic, cultural or social essence of such a natural person.
            </p>
            <p>
            "Processing" means any operation or series of operations with personal data or sets of personal data with or without the use of automated means, such as collection, registration, organization, structuring, storage, adaptation or modification, retrieval, review, use, disclosure by transmission, distribution or otherwise making available, arrangement or combination, restriction, deletion or destruction.
            </p>
            <p>
              Other terms used in this Policy apply in the meanings given in the Law of Ukraine "On the Protection of Personal Data" and General Data Protection Regulation (GDPR).
            </p>
            <p>
            We process your personal data exclusively in the manner described in this Privacy Policy and in compliance with the applicable laws and regualtions.
            </p>
            <p>
            <strong>Questions? </strong>Reading this Privacy Policy will help you understand your privacy rights and entitlements. If you do not agree with our policies and practices, please do not use our Services.
            </p>
            <p>
            <strong>SUMMARY OF KEY POINTS</strong>
            </p>
            <p>
            <strong>What personal data do we process?</strong>When you visit, use or link to our Services, we may process personal data based on your consent based on how you interact with us, the Services and the choices you make, as well as the products and features you use. A detailed list of personal data to which we may request access from you is provided in Section 1 “WHAT PERSONAL DATA DO WE COLLECT? HOW DO WE GET ACCESS TO YOUR DATA?
            </p>
            <p>
            <strong>How do we get access to your personal data? </strong>Access to your personal data may include filling out a form during registration in our application, choosing technical settings for services or behavior that clearly indicates the User's consent to the proposed processing of personal data. We adhere to the principle of transparency, which requires that any information and notices regarding the processing of personal data are accessible and understandable, using clear and simple wording so that the User is aware of the fact and limits of consent.
            </p>
            <p>
            We collect data that you voluntarily provide to us when you express an interest in receiving information about us or our products and Services, when you participate in activities within the Services, or when you otherwise interact with us. Whenever possible, we request access to user data in context (via additional authentication) so that users understand why we need the data.
            </p>
            <p>
            <strong>For what purpose and how do we process your personal data? </strong>We process<strong> </strong>your personal data to provide, improve and administer our Services, communicate with you, for security and fraud prevention, and to comply with the law. We may <strong> process </strong>your personal data if we believe it is necessary to determine usage trends, the effectiveness of our advertising campaigns, and to evaluate and improve our Services, products, and marketing. We may also process your personal data with your consent for other lawful purposes.
            </p>
            <p>
            <strong>How do we store your personal data?  </strong>We store your personal data exclusively in compliance with the requirements for their safety, integrity and a special regime of access to them, which excludes the possibility access to personal data of Users directly or indirectly by third parties. We process personal data exclusively in a way that ensures an appropriate level of security and confidentiality of personal data, including to prevent unauthorized access or use of personal data, as well as equipment necessary for processing and against accidental loss, destruction or damage, using appropriate technical and organizational tools.
            </p>
            <p>
            <strong>Do we provide access to your personal data to third parties?</strong> We may share personal data in specific situations and with specific third parties. A comprehensive list of such situations is defined in Section 3 “WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL DATA?".
            </p>
            <p>
            <strong>Do we process any sensitive information? </strong>We do not process confidential information.
            </p>
            <p>
            <strong>Will we receive any information/data from third parties? </strong>We do not receive any information from third parties.
            </p>
            <p>
            <strong>What are your rights? </strong>
            </p>
            <p>
            1) You have the right<strong> </strong>to receive information about your personal data processed by us, the purposes of the processing, if available, the period of time during which personal data is processed (if this is not possible, the criteria for determining such a period), the recipients of personal data, the logic that underlies any automated processing of personal data, and receipt of confirmation and notification of the personal data that concern you and are subject to processing, the consequences of such processing, categories of relevant personal data. You have the right to receive a copy of personal data that is in the process of processing. We will provide you with information in a concise, transparent, comprehensible and easily accessible form, using clear and simple wording, without unreasonable delay and in any case within one month from the date of receipt of the request.
            </p>
            <p>
            2) You have the right to withdraw your consent to the processing of your personal data at any time. Withdrawal of consent does not affect the lawfulness of processing that was based on consent before its withdrawal.
            </p>
            <p>
            3) You have the right to limit the processing of your personal data or to object to processing, as well as the right to data portability. You have the right to receive your personal data in a structured, generally accepted, machine-readable format and the right to transfer such data to another controller.
            </p>
            <p>
            4) You have the right to send a request for correction or erasure of your personal data;
            </p>
            <p>
            5) You have the "right to be forgotten" if there is no longer a need for personal data for the purposes for which they were collected or otherwise processed; if the retention of such data violates the General Data Protection Regulation (GDPR or European Union or Member State legislation that applies to you. You have the right to withdraw your consent on which the processing is based.
            </p>
            <p>
            Depending on where you are geographically located, the relevant laws of your location may give you certain rights with respect to your personal data.
            </p>
            <p>
            <strong>How do I exercise my rights? </strong>The easiest way to exercise your rights is to contact us: <a href="mailto:contact@deadline.com.ua">contact@deadline.com.ua</a>. We will consider and apply the necessary measures to any request in accordance with the current legislation on the protection of personal data.
            </p>
            <p>
            Want to know more about what  happens with any personal data we collect?
            </p>
            <p>
            <strong>CONTENTS</strong>
            </p>
            <p>
            1. WHAT PERSONAL DATA DO WE PROCESS? HOW DO WE GET ACCESS TO YOUR DATA?
            </p>
            <p>
            2. FOR WHAT PURPOSE AND HOW DO WE PROCESS YOUR PERSONAL DATA?
            </p>
            <p>
            3. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL DATA?
            </p>
            <p>
            4. HOW LONG DO WE KEEP YOUR PERSONAL DATA?
            </p>
            <p>
            5. DO WE COLLECT PERSONAL DATA FROM MINORS?
            </p>
            <p>
            6. WHAT ARE YOUR PRIVACY RIGHTS?
            </p>
            <p>
            7. DO NOT TRACK CONTROLS
            </p>
            <p>
            8. DO CALIFORNIANS HAVE SPECIAL PRIVACY RIGHTS?
            </p>
            <p>
            9. DO WE UPDATE THIS POLICY?
            </p>
            <p>
            10. HOW CAN YOU CONTACT US ABOUT THIS POLICY?
            </p>
            <p>
            11. HOW CAN YOU VIEW, UPDATE OR DELETE THE DATA WE COLLECT FROM YOU?
            </p>
            <p>
            <strong>1. WHAT PERSONAL DATA DO WE PROCESS? HOW DO WE GET ACCESS TO YOUR DATA?</strong>
            </p>
            <p>
            <strong><em>In brief: we collect personal data that you provide to us. </em></strong>
            </p>
            <p>
            We collect data that you voluntarily provide to us when you express interest in receiving information about us or our products and Services, when you participate in activities within the Services, or otherwise when you interact with us.
            </p>
            <p>
            <strong>Personal data provided by you. </strong>The personal data we process depends on the context of your interaction with us and the Services, the choices you make, and the products and features you use.
            </p>
            <p>
            Personal data that we process may include the following:
            </p>
            <ul>

            <li>Your First Name and Last Name, e-mail address or phone number, images (pictures, photos), videos, audio recordings, music files, other files and documents, events from the calendar, your contacts and other information (for example, information about viewing other pages on the Internet, reports on program errors, diagnostics).
            </li>
            </ul>
            <p>
            At the time of registration, we ask you for the following information:
            </p>
            <ul>

            <li>Fist and Last Name;</li>

            <li>Your e-mail and/or telephone number;</li>

            <li>Password (we do not have access to your password).
            </li>
            </ul>
            <p>
            When using the application and the Services, the following information is filled in by the User:
            </p>
            <ul>

            <li>the name of the company where the User works and the User's position;</li>

            <li>some kind of information regarding the specifics of the User's work results in the past.
            </li>
            </ul>
            <p>
            Users, who selected “Journalist” role in the application will be asked to provide a confirmation, such as a press pass, a letter from the editorial office, examples of published works (URL links to signed texts).
            </p>
            <p>
            We only request access to the minimum, technically possible amount of data that is necessary for us to implement the existing functions or services of our application. Whenever possible, we request access to user data in context (via additional authentication) so that users understand why we need the data.
            </p>
            <p>
            <strong>Confidential information. </strong>We do not process confidential information.
            </p>
            <p>
            All personal data you provide to us must be true, complete and accurate, and you must notify us of any changes to such information/data.
            </p>
            <p>
            When using the application, the information about the User that is available to other Users is: User's last name and first name; indicating that the User is an Expert or a Journalist. We process some information automatically using mathematical formulas and publish it for your and other Users' reference.
            </p>
            <p>
              <strong>HOW DO WE GET ACCESS TO YOUR DATA? </strong>
            </p>
            <p>
            Access to your personal data may include filling in a form, choosing technical settings for services or behavior that clearly indicates the User's consent to the proposed processing of personal data. We collect data that you voluntarily provide to us when you express an interest in receiving information about us or our products and Services, when you participate in activities within the Services, or when you otherwise interact with us. Whenever possible, we request access to user data in context (via additional authentication) so that users understand why we need the data.
            </p>
            <p>
            <strong>2. FOR WHAT PURPOSE AND HOW DO WE PROCESS YOUR PERSONAL DATA?</strong>
            </p>
            <p>
            <strong><em>In brief: We use your personal data to provide, improve and administer our Services, communicate with you, for security and fraud prevention, and to comply with the law. We may also process your personal data with your consent for other purposes.</em></strong>
            </p>
            <p>
            <strong>We process your personal data for various reasons, depending on how you interact with our Services, including:</strong>
            </p>
            <ul>

            <li><strong>to evaluate and improve our Services, products, marketing. </strong>We may <strong><em>process </em></strong>your personal data if we believe it is necessary to determine usage trends, the effectiveness of our advertising campaigns, and to evaluate and improve our Services, products, marketing and your experience.
            </li>
            </ul>
            <p>
            If we need to further process personal data for a purpose other than the one for which the personal data was obtained, we will provide you with information about such other purpose and any relevant details specified in the General Data Protection Regulation before such further data processing begins (GDPR).
            </p>
            <p>
              <strong>Do we update your personal data? </strong>
            </p>
            <p>
            We take all reasonable steps to update your data to ensure that inaccurate personal data is deleted or corrected without delay. You have the right to contact us to correct your personal data and the right to fill in incomplete personal data by submitting additional data or correcting the data in your user profile via self-service access.
            </p>
            <p>
              <strong>What are the grounds for restricting personal data? </strong>
            </p>
            <p>
            You have the right to contact us with a statement regarding the restriction of processing of your personal data in the event of the following circumstances: the accuracy of the personal data is contested by you, within a period of time that gives us the opportunity to check the accuracy of the personal data; the processing is illegal and you object to erasure of personal data and instead send a request to restrict their use; we no longer need your personal data for processing purposes, but you require them to form, exercise or defend legal claims; You object to processing pending a review of whether our legitimate grounds override your legitimate interests.
            </p>
            <p>
            We keep records of processing of personal data, which include, among other things, information about: description of categories of data subjects and categories of personal data; categories of recipients to whom personal data has been or will be disclosed, including recipients in third countries or international organizations; if possible, — time limits are provided for erasing different categories of data; if possible, — a general description of technical and organizational security measures; other mandatory information according to the General Data Protection Regulation (GDPR).
            </p>
            <p>
            <strong>3. WHEN AND WHO DO WE SHARE YOUR PERSONAL DATA?</strong>
            </p>
            <p>
            <strong><em>In brief: We may share personal data in the specific situations described in this section and/or with the following third parties.</em></strong>
            </p>
            <p>
            We may need to provide your personal data in the following situations:
            </p>
            <ul>

            <li><strong>Marketing promotion (including personalization and advertising).</strong> We collect information (data) and may also transfer it to third parties or third-party Services (for example: you can receive information in the form of a newsletter to an e-mail address or to your phone number).</li>

            <li><strong>Organizational processes. </strong>We may share or transfer your information (data) in connection with or during negotiations for any merger, sale of company assets, financing or acquisition of all or part of our organizational activities by another company. We transfer your personal data to individuals and legal entities that comply with the terms of the General Data Protection Regulation (GDPR).
            </li>
            </ul>
            <p>
            The information (data) that we collect and process is transmitted in an encrypted form, except for that transmitted to third parties for the purpose of mailings (marketing goals).
            </p>
            <p>
            <strong>4. HOW LONG DO WE KEEP YOUR PERSONAL DATA?</strong>
            </p>
            <p>
            <strong><em>In brief: We take all necessary measures to ensure that the period during which personal data is stored is reduced to an absolute minimum. The criteria for determining the period of storage of the User's personal data is the achievement of the goal(s) defined in Section 2 "FOR WHAT PURPOSE AND HOW DO WE PROCESS YOUR PERSONAL DATA?". </em></strong>
            </p>
            <h5>We retain your personal data for as long as necessary to achieve the purposes set out in this Privacy Policy.</h5>


            <p>
            The user has the right to delete his personal data and stop processing them ("right to be forgotten") if the personal data are no longer necessary for the purposes for which we collect them or otherwise process them, if the data subject has withdrawn his consent or objected against the processing of his or her personal data, and if there is no other lawful basis for the processing, or if the processing of his or her personal data otherwise does not comply with the General Data Protection Regulation (GDPR). In the event of receiving a request for erasure of personal data and in the event of providing your personal data to third parties (in accordance with Section 3), we take appropriate measures, including technical measures, to inform such third parties that process personal data that You have requested the erasure of any references to, copies of, or reproductions of such personal data.
            </p>
            <p>
            We will only retain your personal data for as long as is necessary for the purposes set out in this Privacy Policy, unless a longer retention period is required or permitted by law (for example, tax, accounting or other legal requirements).
            </p>
            <p>
            We may be required to erase your personal data to comply with a statutory obligation under the law that applies to us.
            </p>
            <p>
            Unless we have an ongoing legitimate business need for the processing<strong><em> </em></strong>of your personal data, we will delete or anonymize such information (data).
            </p>
            <p>
            <strong>5. DO WE COLLECT PERSONAL DATA FROM MINORS?</strong>
            </p>
            <p>
            <strong><em>In brief: We do not knowingly collect or sell personal data from children under the age of 18.</em></strong>
            </p>
            <p>
            We do not knowingly collect, process or share personal data from children under the age of 18. If we become aware that personal data has been collected from Users under the age of 18, we will deactivate the account and take steps to immediately delete such data from our records. If you become aware of any data we may have collected by mistake from children under the age of 18, please contact us at <a href="mailto:contact@deadline.com.ua">contact@deadline.com.ua</a>.
            </p>
            <p>
            The right to delete the personal data is preserved, including when the User gave consent as a child and was not fully aware of the risks associated with processing, and later wanted to delete such personal data. The user has the right to request the deletion of data, despite the fact that the User is no longer a child.
            </p>
            <p>
            <strong>6. WHAT ARE YOUR PRIVACY RIGHTS?</strong>
            </p>
            <p>
            <strong><em>In brief: You can review, change or terminate your account at any time.</em></strong>
            </p>
            <p>
            If you are located in the EEA or the UK and believe that we are unlawfully processing your personal data, you also have the right to lodge a complaint with your local data protection supervisory authority. Their contact details can be found here: <a href="https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm">https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm</a>.
            </p>
            <p>
            If you are located in Switzerland, the contact details of the data protection authorities are available here: <a href="https://www.edoeb.admin.ch/edoeb/en/home.html">https://www.edoeb.admin.ch/edoeb/en/home.html</a>.
            </p>
            <p>
            <strong><span style={{textDecoration: 'underline'}}>Withdrawal of your consent: </span></strong>If we rely on your consent to process your personal data, which may be express and/or implied consent depending on applicable law, you have the right to withdraw your consent at any time. You may withdraw your consent at any time by contacting us at the contact details provided under HOW CAN YOU CONTACT US ABOUT THIS POLICY? below.
            </p>
            <p>
            Please note, however, that this will not affect the lawfulness of the processing prior to withdrawal, nor, if permitted by applicable law, will it affect the processing of your personal data that is carried out on the basis of lawful processing.
            </p>
            <p>
            If you have questions or comments about your privacy rights, you can send us an email at <a href="mailto:contact@deadline.com.ua">contact@deadline.com.ua</a>
            </p>
            <p>
            <strong>7. CONTROLS FOR "DO NOT TRACK" FEATURES</strong>
            </p>
            <p>
            Most web browsers, as well as some mobile operating systems and mobile applications, include a Do-Not-Track ("DNT") feature or setting that allows you to limit what data about your browsing activity on the Internet is available to us or third parties. At this stage, a single technological standard for recognizing and implementing DNT signals has not been finalized. Therefore, we do not currently respond to browser DNT signals or any other mechanism that automatically communicates your choice not to be tracked online. If a standard is adopted for online tracking that we must follow in the future, we will notify you of that practice in a revised version of this Privacy Policy.
            </p>
            <p>
            <strong>8. DO CALIFORNIA RESIDENTS HAVE SPECIAL PRIVACY RIGHTS?</strong>
            </p>
            <p>
            <strong><em>In brief: Yes, if you live in California, you have certain rights regarding access to your personal information. </em></strong>
            </p>
            <p>
            California Civil Code Section 1798.83, also known as the "Shine The Light" law, allows our users who are California residents to request and receive from us once a year, free of charge, information about categories of personal information/data (if any) disclosed to third parties for direct marketing purposes, and the names and addresses of all third parties with whom we have shared personal data during the previous calendar year. If you reside in California and wish to make such a request, please send us a written request using the contact information below.
            </p>
            <p>
            <strong>9. DO WE UPDATE THIS POLICY?</strong>
            </p>
            <p>
            <strong><em>In brief: Yes, we will update this Policy as necessary to comply with legal requirements.</em></strong>
            </p>
            <p>
            We may update this Policy from time to time. The updated version will be listed as updated with a "Revised" note, and the updated version will be effective as soon as it becomes available. If we make material changes to this Privacy Policy, we may notify you by posting a notice of such changes in a prominent place or by sending you a notice directly. We encourage you to review this Policy frequently to stay informed of how we are protecting your information.
            </p>
            <p>
            <strong>10. HOW CAN YOU CONTACT US ABOUT THIS POLICY?</strong>
            </p>
            <p>
            If you have any questions or comments regarding this Policy, you can send us an email to <a href="mailto:contact@deadline.com.ua">contact@deadline.com.ua</a>.
            </p>
            <p>
            <strong>11. HOW CAN YOU VIEW, UPDATE OR DELETE THE DATA WE COLLECT FROM YOU?</strong>
            </p>
            <p>
            We take all reasonable steps to ensure that inaccurate personal data is corrected or deleted. In accordance with the applicable laws of your country, you may have the right to request access to the personal data we collect from you, to change this information or to delete it under certain circumstances. To send a request to review, update or delete your personal data, contact us at <a href="mailto:contact@deadline.com.ua">contact@deadline.com.ua</a>
            </p>
            <p>
            <strong>Thank you for your cooperation.</strong>
            </p>
          </div>
        </div>
      </Container>
      <div className="s-100"></div>
      <Footer />
    </>
  )
}
export default Privacy
