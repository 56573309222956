import React, {useState} from 'react'
import { Link } from 'react-router-dom'
import { Container } from 'react-bootstrap'

import Nav from '../parts/Nav'
import Footer from '../parts/Footer'

import PageTitle from '../components/PageTitle'

import enp from '../components/data/enp'
import Price from '../components/cards/Pricing'

import dios from '../assets/img/download/ios-download.png'
import dgp from '../assets/img/download/gp-download.png'

const ios = '/'
const and = '/'

function Pricing() {

  const [period,setPeriod] = useState(1)

  return (
    <>
      <Nav />
      <PageTitle
        mini="mini"
        title="Experience Deadline Expert for free, then continue from just $5/month"
        desc="An app operates on a pre-sale basis – you can pay monthly or enjoy savings with an annual subscription. Enjoy a free trial for the first 14 days">
      </PageTitle>
      <div className="s-100 m-hide"></div>
      <div className="s-100 m-hide"></div>
      <div className="s-40 m-hide"></div>
      <div className="s-100 d-lg-none"></div>
      <div className="s-100 d-lg-none"></div>
      <div className="s-100 d-lg-none"></div>
      <div className="s-100 d-lg-none"></div>
      <Container>
        <h1 className='title-chapter text-center mb-5 pb-md-5'>We offer professional features such as:</h1>
        <div className="row">
          <div className="col-md-6 px-3 mb-3 mb-md-4 px-md-5">
            <h3 className="title-small c-blue bold"><span className="title-small">💬</span>&nbsp;&nbsp;&nbsp;Journalist Engagement</h3>
            <div className="line bg-blue my-3"></div>
            <p className="title-prices lh-125">With our «Starter» plan, users can only introduce themselves to journalists through their pitches. The app algorithm ensures that all users, including «Starters», can only communicate via their pitches. If a journalist finds your pitch intriguing, they will contact you directly via chat. With the basic plans, you can observe the journalist's reaction to your post with some technical limitations. However, our «Personal» plan users benefit from a dedicated PR manager who can independently initiate collaborations, prepare posts or comments, and track outcomes. Sounds impressive, right?</p>
          </div>
          <div className="col-md-6 px-3 mb-3 mb-md-4 px-md-5">
            <h3 className="title-small c-blue bold"><span className="title-small">🏆</span>&nbsp;&nbsp;&nbsp;Pitch Competitions</h3>
            <div className="line bg-blue my-3"></div>
            <p className="title-prices lh-125">Journalists enjoy interacting with quick and interesting people. With the «Expert» and «Personal» plans, your pitches will appear at the top of the list, albeit without any special highlighting. Your pitch can also be showcased to all journalists following a specific topic on Deadline Expert. Please note, Deadline Expert does not display or sell responses from other experts</p>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 px-3 mb-3 mb-md-4 px-md-5">
            <h3 className="title-small c-blue bold"><span className="title-small">🕵️‍♂️</span>&nbsp;&nbsp;&nbsp;Anonymous Pitches</h3>
            <div className="line bg-blue my-3"></div>
            <p className="title-prices lh-125">Our «Expert» plan allows users to enter an incognito mode when creating a post. This feature is ideal for those who prefer not to reveal their identity to the journalist immediately or who wish to avoid recognition by other users</p>
          </div>
          <div className="col-md-6 px-3 mb-3 mb-md-4 px-md-5">
            <h3 className="title-small c-blue bold"><span className="title-small">💻</span>&nbsp;&nbsp;&nbsp;Support</h3>
            <div className="line bg-blue my-3"></div>
            <p className="title-prices lh-125">While all users create their own content, Deadline Expert can provide minor adjustments for publication. Our advanced plans allow our technical support to handle more of the text preparation, freeing up your time for business development or family</p>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 px-3 mb-3 mb-md-4 px-md-5">
            <h3 className="title-small c-blue bold"><span className="title-small">🔎</span>&nbsp;&nbsp;&nbsp;Advanced Search Options</h3>
            <div className="line bg-blue my-3"></div>
            <p className="title-prices lh-125">«Expert» and «Personal» plan users can use various text or calendar criteria to filter pitches. These filters also facilitate user or idea searching</p>
          </div>
          <div className="col-md-6 px-3 mb-3 mb-md-4 px-md-5">
            <h3 className="title-small c-blue bold"><span className="title-small">📰</span>&nbsp;&nbsp;&nbsp;Press Releases and Announcements</h3>
            <div className="line bg-blue my-3"></div>
            <p className="title-prices lh-125">Enhance your interactions with journalists using professionally crafted press releases and post announcements. Deadline Expert delivers your news directly to journalists' personal inboxes, not to a general editorial inbox. We continually refine our code to deliver the best results for you</p>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 px-3 mb-3 mb-md-4 px-md-5">
            <h3 className="title-small c-blue bold"><span className="title-small">🤝</span>&nbsp;&nbsp;&nbsp;Networking Features</h3>
            <div className="line bg-blue my-3"></div>
            <p className="title-prices lh-125">Deadline Expert also enables you to connect with others. When you add a user as a friend, an email or text notification is sent to them. We verify this contact information during registration. The added friend can call you back at their convenience. We limit friend additions to a maximum of 10 people per month. However, if you wish to invite 120 people, this is achievable with an annual subscription</p>
          </div>
          <div className="col-md-6 px-3 mb-3 mb-md-4 px-md-5">
            <h3 className="title-small c-blue bold"><span className="title-small">📈</span>&nbsp;&nbsp;&nbsp;Performance Metrics (KPI)</h3>
            <div className="line bg-blue my-3"></div>
            <p className="title-prices lh-125">Working with journalists requires patience and meticulousness. Press articles are not adverts, journalists seek out engaging and unique speakers. Starting your own comment program is simple, maintaining momentum and robust communication with journalists for an extended period, ideally three to six months, is more challenging. Only after such sustained efforts do noticeable results emerge. Our various plans offer different outcomes for different budgets</p>
          </div>
        </div>
      </Container>
      <Container>
      <div className="my-4 flex jcsa aic">
        <button onClick={() => setPeriod(1)} className={`tab-pricing ${period === 1 ? 'active' : ''}`}>1 month</button>
        <button onClick={() => setPeriod(2)} className={`tab-pricing ${period === 2 ? 'active' : ''}`}>3 month</button>
        <button onClick={() => setPeriod(3)} className={`tab-pricing ${period === 3 ? 'active' : ''}`}>12 month</button>
      </div>
      <Container>
          <div className="row">
            {enp.map((e) => (
              <div className="col-lg-3 col-md-6" key={e.key}>
                <Price
                  radius={e.key}
                  border={e.colors[5]}
                  start={e.colors[0]}
                  stop={e.colors[1]}
                  back={e.colors[2]}
                  color={e.colors[3]}
                  button={e.colors[4]}
                  title={e.title}
                  desc={e.subtitle}
                  type={e.desc}
                  e1={'Dialogues with journalists'}
                  e2={'Anonymous pitches'}
                  e3={'Convenient search'}
                  e4={'Adding to friends'}
                  e5={'Competition for pitches'}
                  e6={'Tech support'}
                  e7={'Processing press releases and announcements'}
                  e8={'KPI'}
                  line1={e.list[0]}
                  line2={e.list[1]}
                  line3={e.list[2]}
                  line4={e.list[3]}
                  line5={e.list[4]}
                  line6={e.list[5]}
                  line7={e.list[6]}
                  line8={e.list[7]}
                  month={'mo'}
                  buy={'buy'}
                  link={period === 1 ? e.link1 : (period === 2 ? e.link2 : e.link3)}
                  price={period === 1 ? e.price1 : (period === 2 ? e.price2 : e.price3)}
                />
              </div>
            ))}
          </div>
      </Container>
      <div className="s-100"></div>
      <div className="s-100"></div>
      </Container>
      <div className='cta py-5'>
        <div className="s-40"></div>
        <h2 className="title-large-cta c-white w-60 mx-auto text-center">The Right Expertise, Delivered</h2>
        <div className="s-40"></div>
        <div className="flex w-100 aic jcc">
          <Link to={ios} className='mx-3 mx-xs-4'><img src={dios} alt="Download for iOS" className='smallest-btn one' /></Link>
          <Link to={and} className='mx-3 mx-xs-4'><img src={dgp} alt="Download for Android" className='smallest-btn two' /></Link>
        </div>
        <div className="s-20"></div>
        <p className="c-lblue text-center">14 day free trial • No credit card required</p>
        <div className="s-40"></div>
      </div>
      <Footer />
    </>
  );
}

export default Pricing
