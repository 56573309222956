import React from 'react'
import Slider from 'react-slick'
import {partners} from '../data/partners'

function SliderTop() {
  var settings = {
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: true,
    arrows: false,
    dots: false,
    infinite: true,
    speed: 2000,
    slidesToShow: 5,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  }

  return (
    <Slider {...settings}>
    {partners.map((item) => (
      <div className="slide logo-slide">
        <a href={item.url} target='blank'>
          <img src={item.pic} alt={item.name} key={item.id} className={'logo-slider theme'} />
          <img src={item.hover} alt={item.name} key={item.id} className={'logo-slider color'} />
        </a>
      </div>
    ))}
    </Slider>
  )
}

export { SliderTop }
