import React, {useState} from 'react'
import { Link } from 'react-router-dom'
import { Container } from 'react-bootstrap'

import Nav from '../parts/NavUA'
import Footer from '../parts/FooterUA'

import PageTitle from '../components/PageTitle'

import uap from '../components/data/uap'
import Price from '../components/cards/Pricing'

import dios from '../assets/img/download/ios-download.png'
import dgp from '../assets/img/download/gp-download.png'

const ios = '/'
const and = '/'

function PricingUA() {

  const [period,setPeriod] = useState(1)

  return (
    <>
      <Nav />
      <PageTitle
        mini="mini"
        title="Тарифні плани для співпраці з Deadline Expert"
        desc="Застосунок працює за моделлю передплати – здійснюйте щомісячну оплату чи заощадьте у разі оплати за рік. Перші 14 днів безкоштовно">
      </PageTitle>
      <div className="s-100 m-hide"></div>
      <div className="s-100 m-hide"></div>
      <div className="s-40 m-hide"></div>
      <div className="s-100 d-lg-none"></div>
      <div className="s-100 d-lg-none"></div>
      <div className="s-100 d-lg-none"></div>
      <div className="s-100 d-lg-none"></div>
      <Container>
        <h1 className='title-chapter text-center mb-5 pb-md-5'>Що пропонують професiйни тарифнi плани?</h1>
        <div className="row">
          <div className="col-md-6 px-3 mb-3 mb-md-4 px-md-5">
            <h3 className="title-small c-blue bold"><span className="title-small">💬</span>&nbsp;&nbsp;&nbsp;Діалоги з журналістами</h3>
            <div className="line bg-blue my-3"></div>
            <p className="title-prices lh-125">Всі користувачі базового тарифу Starter не мають можливості ініціювати діалоги з журналістами напряму через пітч. Алгоритм застосунку організований у такий спосіб, щоб  абсолютно всі користувачі, в тому числі користувачі на тарифі Starter, мали змогу презентувати себе тільки через пітч. Якщо журналіста зацікавить пітч, він зв’яжиться з Вами безпосередньо через чат. В базових тарифах можна вивчати реакцію журналіста на допис з технічними обмеженнями. Але якщо подивитися ще ширше: у користувачів тарифу Personal є особистий піарник, який може самостійно ініціювати співпрацю, підготувати допис чи коментар та відстежити результат. Класно, правда?</p>
          </div>
          <div className="col-md-6 px-3 mb-3 mb-md-4 px-md-5">
            <h3 className="title-small c-blue bold"><span className="title-small">🏆</span>&nbsp;&nbsp;&nbsp;Конкуренція за пітч</h3>
            <div className="line bg-blue my-3"></div>
            <p className="title-prices lh-125">Журналісти віддають перевагу спілкуванню з найшвидшими та найцікавішими. У тарифах Expert та Personal Ваші пітчі будуть з'являтися вгорі списку але без спеціальних позначок (принаймні поки що). Ви також матимете змогу показати свій пітч одночасно всім журналістам застосунку Deadline Expert по конкретній темі. Траплялися випадки, коли одночасно журналіст обирає 10-15 дописів на день з півсотні, тому Ваш допис може загубитися серед напливу. Але звертаємо увагу, що Deadline Expert не показує відповіді інших експертів та не продає ці дані.</p>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 px-3 mb-3 mb-md-4 px-md-5">
            <h3 className="title-small c-blue bold"><span className="title-small">🕵️‍♂️</span>&nbsp;&nbsp;&nbsp;Анонімні пітчі</h3>
            <div className="line bg-blue my-3"></div>
            <p className="title-prices lh-125">Для користувачів, що обирають тариф Expert, налаштування застосунку дозволяють перейти у режим «інкогніто» при створенні допису. Така функція зазвичай буде корисною тим, хто не хоче одразу представлятися журналісту або не хоче, щоб з якоїсь причини інший користувач впізнав автора допису та почав зловживати ситуацією.</p>
          </div>
          <div className="col-md-6 px-3 mb-3 mb-md-4 px-md-5">
            <h3 className="title-small c-blue bold"><span className="title-small">💻</span>&nbsp;&nbsp;&nbsp;Техпідтримка</h3>
            <div className="line bg-blue my-3"></div>
            <p className="title-prices lh-125">Користувачі самостійно готують інформацію. Deadline Expert може лише трохи її підкоригувати для оприлюднення. У персональному тарифі техпідтримка бере на себе більший клопіт із підготовки текстів, щоб Ви проводили більше часу, думаючи про розвиток бізнесу, або з сім'єю.</p>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 px-3 mb-3 mb-md-4 px-md-5">
            <h3 className="title-small c-blue bold"><span className="title-small">🔎</span>&nbsp;&nbsp;&nbsp;Зручний пошук</h3>
            <div className="line bg-blue my-3"></div>
            <p className="title-prices lh-125">Користувачі тарифів Expert та Personal мають змогу фільтрувати пітчі за різними текстовими або календарними критеріями. Фільтри також дозволяють добре зорієнтуватися під час пошуку користувача.</p>
          </div>
          <div className="col-md-6 px-3 mb-3 mb-md-4 px-md-5">
            <h3 className="title-small c-blue bold"><span className="title-small">📰</span>&nbsp;&nbsp;&nbsp;Обробка прес-релізів та анонсів</h3>
            <div className="line bg-blue my-3"></div>
            <p className="title-prices lh-125">Ви зможете підсилювати свою роботу з журналістом професійно складеними пресрелізами, пост-анонсами. Deadline Expert за графіком розсилає Ваші новини журналістам у персональну пошту, а не в редакторське звалище. Так, Deadline Expert удосконалює власний код, щоб у Вас був найкращий результат.</p>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 px-3 mb-3 mb-md-4 px-md-5">
            <h3 className="title-small c-blue bold"><span className="title-small">🤝</span>&nbsp;&nbsp;&nbsp;Додавання в друзі</h3>
            <div className="line bg-blue my-3"></div>
            <p className="title-prices lh-125">Ви можете також використовувати можливості Deadline Expert, щоб потоваришувати з кимось. Коли Ви додаєте користувача в друзі, застосунок надсилає до електронної пошти чи телефону відповідну інформацію фахівцю. Ми перевіряємо дані на етапі реєстрації. Людина, яку Ви додали в друзі, зможе передзвонити Вам зі свого телефону у будь-який момент за потреби. Ви можете додати до друзів з нашою допомогою не більше 10 осіб на місяць. Якщо Ви бажаєте надіслати запрошення 120 людям, це буде можливо при оплаті тарифу за рік.</p>
          </div>
          <div className="col-md-6 px-3 mb-3 mb-md-4 px-md-5">
            <h3 className="title-small c-blue bold"><span className="title-small">📈</span>&nbsp;&nbsp;&nbsp;KPI</h3>
            <div className="line bg-blue my-3"></div>
            <p className="title-prices lh-125">Робота з журналістами вимагає терпіння та ретельності. Статті у пресі це не реклама. Журналісти обирають цікавих та нестандартних спікерів. Запустити власну коментарійну програму може бути неважко. Набагато складніше утримувати заданий темп і вектор спілкування з журналістами протягом кількох місяців, щонайменше три-шість, адже тільки після півроку інтенсивної роботи стають помітними результати праці. Користувачі різних тарифів Deadline Expert за різні гроші отримують різні результати!</p>
          </div>
        </div>
      </Container>
      <Container>
      <div className="my-4 flex jcsa aic">
        <button onClick={() => setPeriod(1)} className={`tab-pricing ${period === 1 ? 'active' : ''}`}>1 місяць</button>
        <button onClick={() => setPeriod(2)} className={`tab-pricing ${period === 2 ? 'active' : ''}`}>3 місяця</button>
        <button onClick={() => setPeriod(3)} className={`tab-pricing ${period === 3 ? 'active' : ''}`}>12 місяців</button>
      </div>
      <Container>
          <div className="row">
            {uap.map((e) => (
              <div className="col-lg-3 col-md-6" key={e.key}>
                <Price
                  radius={e.key}
                  border={e.colors[5]}
                  start={e.colors[0]}
                  stop={e.colors[1]}
                  back={e.colors[2]}
                  color={e.colors[3]}
                  button={e.colors[4]}
                  title={e.title}
                  desc={e.subtitle}
                  type={e.desc}
                  e1={'Діалоги з журналістами'}
                  e2={'Анонімні пітчі'}
                  e3={'Зручний пошук'}
                  e4={'Додавання в друзі'}
                  e5={'Конкуренція за пітчі'}
                  e6={'Технічна підтримка'}
                  e7={'Обробка прес-релізів та оголошень'}
                  e8={'KPI'}
                  line1={e.list[0]}
                  line2={e.list[1]}
                  line3={e.list[2]}
                  line4={e.list[3]}
                  line5={e.list[4]}
                  line6={e.list[5]}
                  line7={e.list[6]}
                  line8={e.list[7]}
                  month={'mo'}
                  buy={'buy'}
                  link={period === 1 ? e.link1 : (period === 2 ? e.link2 : e.link3)}
                  price={period === 1 ? e.price1 : (period === 2 ? e.price2 : e.price3) }
                />
              </div>
            ))}
          </div>
      </Container>
      <div className="s-100"></div>
      <div className="s-100"></div>
      </Container>
      <div className='cta py-5'>
        <div className="s-40"></div>
        <h2 className="title-large-cta c-white w-60 mx-auto text-center">Почніть роботу<br />зараз</h2>
        <div className="s-40"></div>
        <div className="flex w-100 aic jcc">
          <Link to={ios} className='mx-3 mx-xs-4'><img src={dios} alt="Download for iOS" className='smallest-btn one' /></Link>
          <Link to={and} className='mx-3 mx-xs-4'><img src={dgp} alt="Download for Android" className='smallest-btn two' /></Link>
        </div>
        <div className="s-20"></div>
        <p className="c-lblue text-center">14-денна безкоштовна версія • Кредитна картка не потрібна</p>
        <div className="s-40"></div>
      </div>
      <Footer />
    </>
  );
}

export default PricingUA
