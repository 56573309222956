import React from 'react'
import { Link } from 'react-router-dom'

const Pricing = (props) => {
  return (
    <div
      className="price-card card mb-4 rounded-3 shadow-sm text-center au"
      style={{border: `4px solid ${props.border}`}}
      radius={props.radius}
    >
      <div
        className="card-header"
        style={{
          background: `radial-gradient(100% 261.82% at 61.58% 0%, ${props.start} 0%, ${props.stop} 100%)`,
          borderBottom: 'none'
        }}
      >
        <h4 className="title-price c-white p-0 my-3">{props.title}</h4>
        <p className="title-mini c-white c-white my-3">{props.desc}</p>
      </div>
        <h1
          className="title-small c-price py-2"
          style={{backgroundColor: `${props.back}`}}
        >{props.type}</h1>
      <div
        className="card-body"
        style={{backgroundColor: `${props.color}`}}
      >
        <ul className="list-unstyled mt-3 mb-4 text-left">
          <li className='border-bottom py-3 px-4 text-center flex jcsb aic'><span className='title-mini w-4'>{props.line1}</span><span className='w-70 text-left'>{props.e1}</span></li>
          <li className='border-bottom py-3 px-4 text-center flex jcsb aic'><span className='title-mini w-4'>{props.line2}</span><span className='w-70 text-left'>{props.e2}</span></li>
          <li className='border-bottom py-3 px-4 text-center flex jcsb aic'><span className='title-mini w-4'>{props.line3}</span><span className='w-70 text-left'>{props.e3}</span></li>
          <li className='border-bottom py-3 px-4 text-center flex jcsb aic'><span className='title-mini w-4'>{props.line4}</span><span className='w-70 text-left'>{props.e4}</span></li>
          <li className='border-bottom py-3 px-4 text-center flex jcsb aic'><span className='title-mini w-4'>{props.line5}</span><span className='w-70 text-left'>{props.e5}</span></li>
          <li className='border-bottom py-3 px-4 text-center flex jcsb aic h-10'><span className='title-mini w-4'>{props.line6}</span><span className='w-70 text-left'>{props.e6}</span></li>
          <li className='border-bottom py-3 px-4 text-center flex jcsb aic'><span className='title-mini w-4'>{props.line7}</span><span className='w-70 text-left'>{props.e7}</span></li>
          <li className='pt-3 px-4 text-center flex jcsb aic'><span className='title-mini w-4'>{props.line8}</span><span className='w-70 text-left'>{props.e8}</span></li>
        </ul>
        <h1
          className="title-large c-price pt-3 pb-4"
          style={{boxShadow: 'inset 0 1px 10px rgba(0,0,0,.05)', backgroundColor: `${props.back}`, color: `${props.button}`}} >${props.price}
          <span className='small'>/{props.month}</span>
        </h1>
        <Link
          to={props.link}
          className="w-90 btn btn-lg c-white mx-auto my-3"
          style={{backgroundColor: `${props.button}`, borderRadius: 16}}
        >
          {props.buy}
        </Link>
      </div>
    </div>
  )
}
export default Pricing
