import React from 'react'

const Review = (props) => {
  return (
    <div
      className="text-center au"
    >
      <img src={props.photo} alt={props.name} />
      <h4 className="title-small c-dark p-0 mt-3 bold">{props.name}</h4>
      <p className="title-mini c-blue mt-1 mb-0">{props.position}</p>
      <p className="title-mini c-blue mb-1 mt-0">{props.company}</p>
      <p className="title-rev c-dark my-3 px-3">{props.text}</p>
    </div>
  )
}
export default Review
