import React from 'react'
import { Link } from 'react-router-dom'
import { Container } from 'react-bootstrap'

function Footer() {
  return (
    <div className='footer mt-auto py-4'>
      <Container>
        <div className="row">
          <div className="col-md-6 mt-3">
            <p className="nav-link c-dark rights">Clevervol @ 2023. Всі права захищено.</p>
          </div>
          <div className="col-md-6 mt-3 text-right jcfe flex">
            <Link className="nav-link c-dark py-0 mx-4" to="/ua/user-agreement/">Угода користувача</Link>
            <Link className="nav-link c-dark py-0" to="/ua/privacy/">Політика конфіденційності</Link>
          </div>
        </div>
      </Container>
    </div>
  )
}

export default Footer
