import logo001 from '../../assets/img/png/logos/theme/logo001.png'
import logo002 from '../../assets/img/png/logos/theme/logo002.png'
import logo003 from '../../assets/img/png/logos/theme/logo003.png'
import logo004 from '../../assets/img/png/logos/theme/logo004.png'
import logo005 from '../../assets/img/png/logos/theme/logo005.png'
import logo006 from '../../assets/img/png/logos/theme/logo006.png'
import logo007 from '../../assets/img/png/logos/theme/logo007.png'
import logo008 from '../../assets/img/png/logos/theme/logo008.png'
import logo009 from '../../assets/img/png/logos/theme/logo009.png'
import logo010 from '../../assets/img/png/logos/theme/logo010.png'
import logo011 from '../../assets/img/png/logos/theme/logo011.png'
import logo012 from '../../assets/img/png/logos/theme/logo012.png'

import logoh001 from '../../assets/img/png/logos/color/logo001.png'
import logoh002 from '../../assets/img/png/logos/color/logo002.png'
import logoh003 from '../../assets/img/png/logos/color/logo003.png'
import logoh004 from '../../assets/img/png/logos/color/logo004.png'
import logoh005 from '../../assets/img/png/logos/color/logo005.png'
import logoh006 from '../../assets/img/png/logos/color/logo006.png'
import logoh007 from '../../assets/img/png/logos/color/logo007.png'
import logoh008 from '../../assets/img/png/logos/color/logo008.png'
import logoh009 from '../../assets/img/png/logos/color/logo009.png'
import logoh010 from '../../assets/img/png/logos/color/logo010.png'
import logoh011 from '../../assets/img/png/logos/color/logo011.png'
import logoh012 from '../../assets/img/png/logos/color/logo012.png'

const partners = [
  {
    id: 0,
    name: 'ukrinform',
    pic: logo001,
    hover: logoh001,
    url: 'https://www.ukrinform.ua/'
  },
  {
    id: 1,
    name: 'tva',
    pic: logo002,
    hover: logoh002,
    url: 'https://tva.ua/onair/'
  },
  {
    id: 2,
    name: 'suspilne',
    pic: logo003,
    hover: logoh003,
    url: 'https://tv.suspilne.media/'
  },
  {
    id: 3,
    name: '1plus1',
    pic: logo004,
    hover: logoh004,
    url: 'https://1plus1.ua/ru'
  },
  {
    id: 4,
    name: 'liga.net',
    pic: logo005,
    hover: logoh005,
    url: 'https://www.liga.net/'
  },
  {
    id: 5,
    name: 'hromadske',
    pic: logo006,
    hover: logoh006,
    url: 'https://hromadske.ua/ru'
  },
  {
    id: 6,
    name: 'espreso.tv',
    pic: logo007,
    hover: logoh007,
    url: 'https://ru.espreso.tv/'
  },
  {
    id: 7,
    name: 'allretail',
    pic: logo008,
    hover: logoh008,
    url: 'https://allretail.ua/ru'
  },
  {
    id: 8,
    name: 'nachasi',
    pic: logo009,
    hover: logoh009,
    url: 'https://nachasi.com/'
  },
  {
    id: 9,
    name: 'dev.ua',
    pic: logo010,
    hover: logoh010,
    url: 'https://dev.ua/'
  },
  {
    id: 10,
    name: '33kanal',
    pic: logo011,
    hover: logoh011,
    url: 'https://33kanal.com/'
  },
  {
    id: 11,
    name: 'nashkiev',
    pic: logo012,
    hover: logoh012,
    url: 'https://nashkiev.ua/'
  }
]

export {partners}
