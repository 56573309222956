import React from 'react'
import { Container } from 'react-bootstrap'

import Nav from '../parts/NavUA'
import Footer from '../parts/FooterUA'

import PageTitle from '../components/PageTitle'

function TermsUA() {
  return (
    <>
      <Nav />
      <PageTitle
        mini="mini"
        title="УГОДА ПРО МОЖЛИВОСТІ КОРИСТУВАННЯ"
        desc="Дата оновлення: 18 травня 2023р.">
      </PageTitle>
      <div className="s-100 m-hide"></div>
      <div className="s-100 m-hide"></div>
      <div className="s-40 m-hide"></div>
      <Container>
        <div className="section-page">
          <div className="container mt-2xl mb-2xl page">


            <h3>Вітаємо у Deadline Expert<br />Ознайомтеся з інформацією щодо облікового запису
            </h3>

            <p>Ця Угода про можливості користування є Угодою між Вами та CLEVERVOL INC, яка регулює надання послуг. Документ укладається між адміністрацією Корпорації CLEVERVOL INC, що організовує доступ до мобільного застосунку (прикладної комп`ютерної програми) Deadline Expert (доступ до Послуг, «ми», «нас», «наш») і Користувачем  (приватною особою або представником юридичної особи / директором, публічним діячем, найманим працівником або ін.).</p>

            <p>Метою укладання цієї Угоди є сумісна діяльність через покрокові алгоритми, набуття вигід від співпраці виключно на користь користувачів:</p>

            <ul>
              <li>отримання додаткового GRP (що таке GRP – див. посилання);</li>
              <li>виявлення та надання  більш рівномірного та зрозумілого доступу до онлайн чи друкованих медіа (преси, блогерів, тощо);</li>
              <li>впровадження практик тайм-менеджменту, контент-менеджменту та іншого досвіду в роботу різних інформаційних сайтів чи підпрограм (без обмежень).</li>
            </ul>

            <p>Доступ до прикладної комп`ютерної програми Deadline Expert надається в стані «як є». Послуги не стосуються людей (дітей) віком до 18 років.</p>

            <p>Текст документа створено з урахуванням практики колег та Законодавства.
            Ця Угода є підставою для укладання інших можливих Угод.</p>

            <p>Будь-ласка, ознайомтеся із текстом повністю.</p>

            <h4>1. Хто такий користувач</h4>

            <div className="mx-3">

              <p>1.1. Відвідувачем програми може бути будь-яка приватна особа (або представник юридичної особи / директор, громадський діяч, найманий співробітник або ін.), яка подивилась хоча б одну її сторінку без попередньої реєстрації та авторизації.</p>

              <p>1.2. Відвідувач, що зареєструвався та авторизувався з використанням своїх індивідуальних данних стає користувачем.</p>

              <p>1.3. Під час реєстрації, користувач може обрати на свій власний розсуд один з двох статусів роботи у програмі:</p>

              <ul>
                <li>«Журналіст». Для розміщення дописів (текстових, фото-, відео- матеріалів), що пов’язані з відповідною професійною діяльністю, для виконання будь-яких професійних обов’язків, наприклад, для вивчення кон’юнктури ринку чи дослідження окремого питання.</li>
                <li>«Експерт». Для обміну текстовими, фото-, відео- повідомленнями, для презентації власних можливостей чи будь-яких професійних повноважень, для реалізації інших можливостей чи ідей, наприклад, для пошуку інформаційних партнерів.</li>
                <p>Зміна статусу можлива впродовж подальшої співпраці.</p>
              </ul>

              <p>1.4. Користувачу надається доступ до розширених можливостей.</p>

              <p>1.5. Для того щоб використовувати можливості, користувачеві необхідно погодитися з цією угодою. Якщо користувач не прийняв умови угоди, він не має права користуватися програмою.</p>
            </div>

            <h4>2. Як працювати із текстовими (фото-, відео-) матеріалами</h4>

            <div className="mx-3">

              <p>2.1. Зареєстрованим відвідувачам (користувачам) Deadline Expert надаєтьcя можливість пропонувати та отримувати інформацію (фото- відео-), коментувати надану інформацію, публікувати власні думки, доводи, спостереження, вести онлайн спілкування чи перемовини з метою досягнення бажаного результату, з використанням автоматичних математичних алгоритмів програми.</p>

              <p>2.2. Права користувача у програмі:</p>

              <ul>
                <li>створювати дописи, висловлюючи думки та спостереження;</li>
                <li>коментувати дописи, передавати, використовувати їх та інші файли і документи (текстові, фото-, відео-);</li>
                <li>використовувати Deadline Expert на свій розсуд та з метою вирішувати комунікаційні завдання, що не суперечать основам його діяльності;</li>
                <li>у разі необхідності, звернутися за допомогою.</li>
              </ul>

              <p>2.3. Авторські права на матеріали та програмний код, якщо не вказано інше, є власністю CLEVERVOL INC.</p>

              <p>2.4. Кожна офіційно оформлена торгова марка, слоган, символ і назви на законних підставах належать відповідним власникам. Вживання символів (R) і / або ™ в матеріалах не є обов'язовою умовою.</p>

              <p>2.5. Користувач усвідомлює і погоджується з Політикою Конфіденційності, що є невід’ємною складовою цією Угоди і пояснює як зберігаються і використовуються Ваші персональні дані.</p>
            </div>



            <h4>3. Як правильно коментувати та створювати дописи</h4>

            <div className="mx-3">
              <p>3.1. Всі користувачі Deadline Expert при створенні будь-яких текстових дописів чи коментарів зобов'язані дотримуватися описаних тут принципів.</p>
              <p>3.2. Всі користувачі однаково несуть відповідальність за розміщення інформації та користування Deadline Expert згідно умов Угоди і Законодавства; при цьому не ознайомлення з обмеженнями і правилами не дає право на недотримання цих правил або ухилення від них.</p>
              <p>3.3. Прямо забороняється:</p>
              <ul>
                <li>Вказувати про себе завідомо неправдиві відомості з метою обману інших користувачів, для навмисного некоректного спілкування з журналістами або експертами. В т.ч. створювати облікові записи, в яких ім'я користувача імітує службові імена (адміністратор, модератор і т.д.). Адміністрація CLEVERVOL INC робить все можливе, щоб дані користувачів були якомога більш повними і точними.</li>
                <li>У будь-якій формі (при надсиланні прес-релізів, запитів або відповідей) закликати до насильницької зміни конституційного ладу, захоплення державної влади, закликати до зміни державних кордонів.</li>
                <li>Закликати до порушення порядку, погромів, підпалів, захоплення або знищення майна. Навмисне закликати до агресії або пролонгації будь-якого військового конфлікту.</li>
                <li>Ображати інших користувачів Deadline Expert або з допомогою Deadline Expert, зокрема, експертів, підприємців, політиків, чиновників, представників ЗМІ, інших людей за національною, расовою або релігійною ознакою, проводити шовіністичні агітації.</li>
                <li>Оприлюднювати, відправляти в повідомленнях висловлювання образливого, порнографічного, еротичного або сексуального характеру. А також розміщувати фото і відео контент еротичного чи порнографічного змісту.</li>
                <li>Образлива поведінка (висловлювання, повідомлення, листування) по відношенню до користувачів чи Адміністраторів CLEVERVOL INC.</li>
                <li>Розповсюджувати прес-релізи, дописи та реакції, метою яких є пряма реклама, комерційні пропозиції, а також публікувати повідомлення, які не мають інформаційного навантаження (в т.ч. для оголошень про купівлю-продаж), що не відносяться до роботи журналістів або блогерів. Опубліковувати спам, посилання на сторонні ресурси (якщо це не передбачено характером допису журналіста). Щоб обговорити рекламу, будь ласка, зв'яжіться заздалегідь: <a href="mailto:contact@deadline.com.ua">contact@deadline.com.ua</a>.</li>
                <li>Флуд або численне копіювання і відправка однакових прес-релізів, дописів або повідомлень, а також флуд в листуванні, яке ведеться за допомогою чату.</li>
                <li>Втручатися в роботу Deadline Expert (в т.ч. зі спробою злому або крадіжки даних), намагатися внести зміни в програмний код продукту.</li>
                <li>Будь-які інші дії, які заборонені Законодавством.</li>
              </ul>
            </div>

            <h4>4. Відповідальність за розміщення інформації</h4>

            <div className="mx-3">

              <p>4.1.  У Deadline Expert користувачам надається зона для спілкування (чат, система коментування та ін.). Висловити свою думку з будь-якої тематики може будь-який користувач.</p>

              <p>4.2. Відповідальність за цінність та корректність інформації, розміщеної у розділах «Мої пітчі», «Мій профіль», «Мої чати», в коментарях і так далі несе виключно її автор. Вищезазначені розділи можуть піддаватися пре-модерації та пост-модерації - перевірці повідомлень модератором (уповноваженим представником Адміністрації CLEVERVOL INC) перед розміщенням або після нього.</p>

              <p>4.3. При виявленні користувачем інформації, яка шкодить інтересам кого-небудь, йому спершу необхідно особисто зв'язатися з автором такої інформації для спроби врегулювання суперечки в межах можливостей Deadline Expert. Користувач також має змогу звернутися до Адміністрації за допомогою з приводу таких ситуацій, але після самостійної спроби.</p>

              <p>4.4. При недотриманні користувачем пунктів цієї Угоди або його небажання / нездатність дотримуватися правил Угоди Адміністрація CLEVERVOL INC має право зупинити дію Угоди по відношенню до користувача.</p>
              <p>4.5. Профіль користувача, що порушив чинну Угоду, може бути заблокований; повністю або частково заблокований доступ до застосунку та його функцій.</p>

              <p>4.6. При необхідності оскарження рішень модератора допускається контакт з Адміністрацією CLEVERVOL INC за умови чітко обґрунтованої позиції.</p>

            </div>
              <h4>5. Угода про конфіденційність</h4>
              <div className="mx-3">
                <p>5.1. Порядок збору, використання і розголошення інформації, яка може бути визнана конфіденційною, здійснюється на підставі даного пункту Угоди про конфіденційність в частині використання персоніфікованої і неперсоніфікованої конфіденційної інформації відповідно.</p>
                <p>5.2. Адміністрація CLEVERVOL INC робить все можливе, щоб дані користувачів були якомога більш повними і точними. Збір, зберігання, використання, обробка, розголошення інформації, отриманої адміністрацією застосунку в результаті відвідування приватною особою (або представником юридичної особи / директором, публічним діячем, найманим співробітником або ін.), відвідувачем або користувачем і / або зберігання даних реєстраційних форм, здійснюється відповідно до Законодавства та пояснюється Політикою Конфіденційності. Користувач усвідомлює і надає згоду на збір і обробку своїх персональних даних, в рамках і з метою, передбачених умовами Угоди.</p>
            </div>

            <h4>6. Пояснення умов роботи з даними</h4>
            <div className="mx-3">
              <p>Цей розділ інформує про нашу політику щодо збору, використання та розповсюдження даних під час вибору, який Ви пов'язали з цими даними.</p>
              <p>Ми збираємо, опрацьовуємо та зберігаємо Ваші дані для вдосконалення власної праці, в тому числі, згідно вимог Європейського Союзу. Ми пояснюємо ці умови щодо використання даних, тому що деякі наші користувачі можуть знаходитися в зоні ЄС.</p>
              <p>6.1. Збір та використання інформації</p>
              <p>Ми збираємо кілька різних типів інформації для різних цілей, щоб забезпечити та покращити Ваше обслуговування. Типи даних, які ми збираємо:</p>
              <ul>
                <li>Особисті дані. Під час користування Deadline Expert ми можемо попросити Вас надати певну персональну інформацію, яка може використовуватися для зв'язку з Вами. Особиста інформація може включати:
                  <div className="mx-3">
                    <p>адресу електронної пошти;</p>
                    <p>ім'я та прізвище;</p>
                    <p>номер телефону;</p>
                    <p>місце роботи (загальна інформація);</p>
                    <p>кукі та дані про використання;</p>
                    <p>інше.</p>
                  </div>
                </li>
                <li>Дані про використання. Вони можуть містити таку інформацію, як IP-адреса, тип браузера, версія браузера, час і дата відвідування,  унікальний ідентифікатори пристрою та інші діагностичні дані.</li>
                <li>Відстеження та дані файлів cookie. Ми використовуємо файли cookie та аналогічні технології, щоб спостерігати за діяльністю Deadline Expert та сайту <a href="https://clevervol.com">https://clevervol.com</a>, щоб збирати та зберігати певну інформацію.
                  <p className='mx-3'>Файли cookie – це файли з невеликою кількістю даних, які можуть містити анонімний унікальний ідентифікатор. Файли cookie зазвичай надсилаються у веб-браузер із веб-сайтів та зберігаються на Вашому пристрої. Вони допомагають веб-сайтам працювати швидше та ефективніше, а також забезпечують власників веб-сайтів різною інформацією у тому числі інформацією про вподобання для цілей реклами та таргетингу. У цій угоді ми використовуємо термін «сookie», але він охоплює всі подібні технології, такі як веб-маяки, SDK, реєстраційні файли (лог-файли), піксельні теги.</p>
                </li>
              </ul>
              <p>6.2. Передача даних</p>
              <ul>
                <li>Інформація, включаючи дані розділу «Мій профіль», може бути передана для зберігання комп’ютерам, що знаходяться за межами Вашої країни, де закони про захист даних можуть відрізнятись від законів про захист даних конкретної держави.</li>
                <li>Ваша згода з цим розділом є згодою на цю передачу.</li>
              </ul>
              <p>6.3. Безпека даних</p>
              <p>Безпека Ваших даних важлива для нас, але пам'ятайте, що жоден метод передачі через Інтернет, ані метод електронного зберігання не є абсолютно безпечним. Хоча ми прагнемо використовувати комерційно прийнятні засоби для захисту Ваших даних, ми не можемо гарантувати їх абсолютну безпеку.</p>
              <p>6.4. Самостійна співпраця з Google Analytics</p>
              <p>Google Analytics – це служба веб-аналітики, яку пропонує Google, щоб звітувати про трафік. Google використовує зібрані дані для відстеження та контролю ефективності. Ця стороння служба аналітики може використовувати зібрані дані для персоналізації оголошень власної рекламної мережі. Ви  можете самостійно відмовитися від того, щоб зробити свою діяльність доступною для Google Analytics, встановивши надбудову веб-браузера Google Analytics для відмови від Google. Додаток перешкоджає JavaScript Google Analytics (ga.js, analytics.js та dc.js) надавати інформацію про Ваші відвідування в Google Analytics. Щоб дізнатися більше про практику конфіденційності Google, відвідайте веб-сторінку та ознайомтеся з інструкціями безпосередньо від Google.</p>
              <p>6.5. Постачальники інших послуг користувачам</p>
              <p>Ми можемо використовувати послуги сторонніх компанії, щоб розвивати корисні можливості Deadline Expert для Вас. Ці треті сторони можуть мати доступ до Ваших даних лише для виконання цих завдань від нашого імені та зобов'язані не розголошувати їх.</p>
              <p>Також, нам може знадобитися надання Ваших персональних даних в таких ситуаціях:</p>
              <ul>
                <li><strong>Маркетингова мета</strong> (у т.ч. персоналізація та реклама). Ми збираємо інформацію (дані) і також можемо передавати їх третім особам чи стороннім Сервісам (наприклад, щоб Ви мали можливість отримувати інформацію у вигляді розсилки на електрону адресу або на вказаний номер телефону). Наприклад, ми використовуємо Mixpanel (www.mixpanel.com) для вивчення поведінки відвідувачів та користувачів.</li>
                <li><strong>Організаційні процеси</strong>. Ми можемо поділитися або передати інформацію (дані) у зв’язку з будь-яким злиттям, продажем активів компанії, фінансуванням або придбанням всієї або частини нашої організаційної діяльності іншій компанії або під час переговорів про це. Ми здійснюємо передавання Ваших персональних даних фізичним і юридичним особам, які дотримуються умов про захист даних.</li>
              </ul>
              <p>Інформація (дані), яку ми збираємо та опрацьовуємо передається у зашифрованому вигляді, крім тієї, що передається третім особам з метою розсилок (маркетинговою метою).</p>

              <p>6.6. Цілі збирання та зберігання даних користувачів</p>

              <p>Кінцевою метою використання зібраних даних є виконання умов даної Угоди з боку адміністрації CLEVERVOL INC перед користувачами:</p>

              <ul>
                <li>Підтримка та консультації щодо Послуг;</li>
                <li>Повідомлення про зміни у Послугах;</li>
                <li>Запрошення щодо участі в інтерактивних функціях;</li>
                <li>Аналіз корисної інформації для подальшого вдосконалення;</li>
                <li>Контроль за якістю надання Послуг;</li>
                <li>Виявлення, запобігання та вирішення технічних проблем.</li>
              </ul>

              <h4>7. Строки надання Послуг на комерційній основі:</h4>
              <div className="mx-3">
                <p>7.1. Користувачі застосунку Deadline Expert можуть замовити додаткові Послуги. Перелік платних пропозицій розміщений на веб-сайті у спеціальному розділі <a href="https://clevervol.com/ua/pricing/">https://clevervol.com/ua/pricing/</a> та візуально оформлений.</p>
                <p>7.2. Усі ціни, зазначені на веб-сайті, є цінами брутто у доларах США (включаючи податок на додану вартість). Для громадян Україні автоматичний перерахунок відбувається на умовах фінансової установи по курсу Національного банку України на момент платежу.</p>
                <p>7.3. Щоб скористатися комерційними можливостями, необхідно здійснити передоплату на таку обрану Послугу в повному обсязі (аванс) до початку її надання – за допомогою встановлених на веб-сайті платіжних форм.</p>
                <p>7.4. Замовлення послуг та їхня оплата веде до надання такому Користувачеві статусу «Бізнес-користувач».</p>
                <p>7.5. Надання комерційної послуги має розпочатися у Березнi 2024 року або по готовності.  «Бізнес-користувач» має право вимагати від виконавця повернути кошти за ненадані послуги, якщо виконавець послуг CLEVRVOL INC (або інша особа, що обрана виконавцем послуг), відмовився від виконання Угоди.</p>
                <p>7.6. Послуги з прийому та обробки платежів здійснює STRIPE INC (www.stripe.com).</p>
              </div>

              <h4>8. Прикінцеві положення</h4>
              <div className="mx-3">
                <p>8.1. Заповнена відвідувачем реєстраційна форма програми Deadline Expert має на увазі прийняття ним умов даної Угоди. Незалежно від наявності або відсутності практичного використання програми користувач зобов'язаний прийняти умови Угоди. Користування можливостями Deadline Expert прирівнюється до прийняття користувачем умов Угоди з моменту реєстрації.</p>
                <p>8.2. Адміністрація CLEVERVOL INC діє з урахуванням Законодавства. Всі офіційні заяви, розбіжності, конфлікти і скарги вивчаються відповідно цієї позиції.</p>
                <p>8.3. Адміністрація CLEVERVOL INC, будь-які представники, власники, керівніки та ін. знімають з себе відповідальність за будь-які дії або бездіяльність, що стали результатом прямих або непрямих збитків або втрати вигоди користувачами, відвідувачами або третіми особами при користуванні або некористуванні програмою.</p>
                <p>8.4. Даною Угодою користувач дає свою згоду взяти на себе відповідальність за недотримання ним будь-яких умов цієї Угоди і за всі можливі наслідки такого недотримання, в тому числі і матеріальний і інший збиток, нанесений адміністрації CLEVERVOL INC в ході порушень умов данної Угоди.</p>
                <p>8.5. Дії, необхідні для надання доступу до мережі Інтернет, купівля чи встановлення відповідного обладнання не підпадають під дію Угоди та вирішуються на стороні користувача самостійно.</p>
                <p>8.6. Умови цього документа можуть бути змінені без попередження або з попередженням.</p>
                <p>8.7. Якщо у Вас є запитання чи коментарі щодо інших прав чи можливостей, Ви можете надіслати нам електронний лист на адресу <a href="mailto:contact@deadline.com.ua">contact@deadline.com.ua</a></p>
                <p>8.8. Електронна адреса для інших запитань: <a href="mailto:contact@deadline.com.ua">contact@deadline.com.ua</a></p>
              </div>

              <h3>Дякуємо за співпрацю.</h3>
            </div>
          </div>
       </div>
      </Container>
      <div className="s-100"></div>
      <Footer />
    </>
  )
}
export default TermsUA
