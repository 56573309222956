import React  from 'react'
import { Link } from 'react-router-dom'
import { Container } from 'react-bootstrap'

import { SliderTop } from '../components/sliders/SliderTop'
import { SliderRevs } from '../components/sliders/SliderRevs'

import Nav from '../parts/NavUA'
import Footer from '../parts/FooterUA'

import PageTitle from '../components/PageTitle'

import dios from '../assets/img/download/ios-download.png'
import dgp from '../assets/img/download/gp-download.png'

import feat1 from '../assets/img/features/feat_001.png'
import feat2 from '../assets/img/features/feat_002.png'
import feat3 from '../assets/img/features/feat_003.png'

import screens from '../assets/img/screens/UA.png'

const ios = '/'
const and = '/'

function Home() {

  return (
    <>
      <Nav />
      <PageTitle
        title="Розкажіть свою історію з
        Deadline Expert"
        desc="Застосунок Deadline Expert допомагає створювати
        репортажі журналісту, знаходити фактуру та героїв
        телесюжетів. Експерти та піарники отримують
        безкоштовні згадки">
        <div className="flex w-100 aic jcc au">
          <Link to={ios} className='mx-md-3'><img src={dios} alt="Download for iOS" className='bigger-btn' /></Link>
          <Link to={and} className='mx-md-3'><img src={dgp} alt="Download for Android" className='bigger-btn' /></Link>
        </div>
        <div className="s-30"></div>
        <p className="title-mini c-blue au">14-денна безкоштовна версія • Кредитна картка не потрібна</p>
        <div className="s-30"></div>
        <p className="title-small c-card au">Робіть відомими себе та свої компанії на рівні з іншими капітанами галузі</p>
        <div className="s-30"></div>
        <div><SliderTop /></div>
        <div className="s-100 d-lg-none"></div>
        <div className="s-100 d-lg-none"></div>
        <div className="flex w-100 aic jcc mt-3">
          <img className='screens w-70 ma' src={screens} alt="Screens" />
        </div>
      </PageTitle>
      <div className="s-100 m-hide"></div>
      <div className="s-100 m-hide"></div>
      <div className="s-100"></div>
      <div className="s-100"></div>
      <div className="s-100 d-lg-none"></div>
      <div className="s-100 d-lg-none"></div>
      <div className="s-100 d-lg-none"></div>
      <div className="s-100 d-lg-none"></div>
      <div className="s-100 d-lg-none"></div>
      <div className="s-100 d-lg-none"></div>
      <Container>
        <div id="about">
          <h1 className='title-large text-center'>Стати героєм публікації реально</h1>
          <div className="s-10 m-hide"></div>
          <h3 className='title-medium text-center w-80'>Кожен день у застосунку Deadline Expert з'являються завдання від різних медіа. Від великих видань, національних та регіональних каналів до блогів про моду і красу. Кожен може знайти тему, в якій стане експертом. Неважливо хто Ви: політик, бізнесмен, блогер, співак або менеджер</h3>
          <div className="row">
            <div className="col-md-6 flex h-80 aic a-hide">
              <div className="mx-5">
              <img src={feat1} alt="Get discovered by mobile" />
              </div>
            </div>
            <div className="col-md-6 flex h-80 aic">
              <div className="mx-5">
              <h5 className="title-mini c-blue">#1 ОСОБЛИВІСТЬ</h5>
              <div className="s-10"></div>
              <h3 className="title-large c-dark">Заявіть про себе телефоном</h3>
              <div className="s-10"></div>
              <p className="title-small c-dark">Застосунок дозволяє миттєво спілкуватися. Оперативність та простота у використанні робить Deadline Expert унікальним та зрозумілим громадянам</p>
              </div>
            </div>
            <div className="col-md-6 flex h-80 aic m-hide">
              <div className="mx-5">
              <img src={feat1} alt="Get discovered by mobile" />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 flex h-80 aic">
              <div className="mx-5">
              <img src={feat2} alt="Personal content strategy" />
              </div>
            </div>
            <div className="col-md-6 flex h-80 aic">
              <div className="mx-5">
              <h5 className="title-mini c-blue">#2 ОСОБЛИВІСТЬ</h5>
              <div className="s-10"></div>
              <h3 className="title-large c-dark">Ще більше контактів з фахівцями</h3>
              <div className="s-10"></div>
              <p className="title-small c-dark">Реалізуйте контент-стратегію, знайдіть потрібних людей за темою, сферою діяльності чи аналогічним досвідом. Ви також можете створювати свої власні списки та аналізувати інформацію для подальшої співпраці</p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 flex h-80 aic a-hide">
              <div className="mx-5">
              <img src={feat3} alt="Motion yourself and make your brand" />
              </div>
            </div>
            <div className="col-md-6 flex h-80 aic">
              <div className="mx-5">
              <h5 className="title-mini c-blue">#3 ОСОБЛИВІСТЬ</h5>
              <div className="s-10"></div>
              <h3 className="title-large c-dark">Спільне вирішення бізнес-завдань</h3>
              <div className="s-10"></div>
              <p className="title-small c-dark">Deadline Expert рекомендує експертів та піарників журналістам і медіа. Ви можете поділитись останніми тенденціями та оновленнями Вашої справи з усім світом, просувати свій бізнес на місцевому рівні або просувати партнерство. На зв'язку журналісти, які хочуть працювати саме з Вами</p>
              </div>
            </div>
            <div className="col-md-6 flex h-80 aic m-hide">
              <div className="mx-5">
              <img src={feat3} alt="Motion yourself and make your brand" />
              </div>
            </div>
          </div>
        </div>
      </Container>
      <div className="s-100"></div>
      <Container>
        <h1 className='title-large text-center mb-5 pb-md-5'>Про нас говорять</h1>
        <div className="row">
          <SliderRevs />
        </div>
      <div className="s-100"></div>
      <div className="s-100"></div>
      </Container>
      <div className='cta py-5'>
        <div className="s-40"></div>
        <h2 className="title-large-cta c-white w-60 mx-auto text-center">Почніть роботу<br />зараз</h2>
        <div className="s-40"></div>
        <div className="flex w-100 aic jcc">
          <Link to={ios} className='mx-3 mx-xs-4'><img src={dios} alt="Download for iOS" className='smallest-btn one' /></Link>
          <Link to={and} className='mx-3 mx-xs-4'><img src={dgp} alt="Download for Android" className='smallest-btn two' /></Link>
        </div>
        <div className="s-20"></div>
        <p className="c-lblue text-center">14-денна безкоштовна версія • Кредитна картка не потрібна</p>
        <div className="s-40"></div>
      </div>
      <Footer />
    </>
  )}

export default Home
