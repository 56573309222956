import React from 'react'
import Slider from 'react-slick'
import rev from '../data/rev'
import Review from '../cards/Review'

function SliderRevs() {
  var settings = {
    autoplay: true,
    autoplaySpeed: 10000,
    pauseOnHover: true,
    arrows: false,
    dots: true,
    infinite: false,
    speed: 4000,
    slidesToShow: 4,
    slidesToScroll: 4,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  }

  return (
    <Slider {...settings}>
      {rev.map((e) => (
        <div>
          <Review
            key={e.id}
            photo={e.photo}
            name={e.name}
            position={e.position}
            company={e.company}
            text={e.text}
          />
        </div>
      ))}
    </Slider>
  )
}

export { SliderRevs }
