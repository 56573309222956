import React from 'react'
import { Container } from 'react-bootstrap'

import Nav from '../parts/NavUA'
import Footer from '../parts/FooterUA'

import PageTitle from '../components/PageTitle'

function PrivacyUA() {
  return (
    <>
      <Nav />
      <PageTitle
        mini="mini"
        title="ПОЛІТИКА КОНФІДЕНЦІЙНОСТІ"
        desc="Дата оновлення: 11 травня 2023 р.">
      </PageTitle>
      <div className="s-100 m-hide"></div>
      <div className="s-100 m-hide"></div>
      <div className="s-40 m-hide"></div>
      <Container>
        <div className="section-page">
          <div className="container mt-2xl mb-2xl page">
            <p>
            Документ укладається між адміністрацією Корпорації CLEVERVOL INC (далі по тексту «ми», «наш», «нас») та Користувачем (приватною особою або представником юридичної особи / директором, публічним діячем, найманим працівником або ін.), щоб пояснити те як і чому ми можемо опрацьовувати Ваші персональні дані (дані користувачів), коли Ви користуєтеся нашими Послугами (далі «Послуги»): користуєтесь нашим застосунком, прикладною комп’ютерною програмою <strong>Deadline Expert</strong>, яка містить посилання на цю Політику конфіденційності (далі по тексту «Сервіс», «Сервіси»); взаємодіючі з нами у будь-який спосіб, включаючи продажі чи маркетинг.
            </p>
            <p>
              <strong>Персональні дані</strong> означають будь-яку інформацію, що стосується фізичної особи, яку ідентифіковано чи можна ідентифікувати (тобто «суб’єкт даних»). Фізична особа, яку можна ідентифікувати, є такою особою, яку можна ідентифікувати, прямо чи опосередковано, зокрема, за такими ідентифікаторами як ім’я, ідентифікаційний номер, дані про місцеперебування (онлайн-ідентифікатор у Вашому пристрої) або за одним чи декількома факторами, що є визначальними для фізичної, фізіологічної, генетичної, розумової, економічної, культурної чи соціальної сутності такої фізичної особи.
            </p>
            <p>
            Під «опрацюванням» мається на увазі будь-яка операція або низка операцій з персональними даними або наборами персональних даних із використанням автоматизованих засобів або без них, такі як: збирання, реєстрація, організація, структурування, зберігання, адаптація чи зміна, пошук, ознайомлення, використання, розкриття через передавання, розповсюдження чи надання іншим чином, упорядкування чи комбінування, обмеження, стирання чи знищення таких даних.
            </p>
            <p>
              Інші терміни, що використовуються у цій Політиці вживаються у значеннях, наведених у Законі України «Про захист персональних даних» та Загальному регламенті про захист даних (GDPR).
            </p>
            <p>
            Ваші персональні дані збираються, зберігаються та використувуються виключно у такий спосіб, що описаний у цій Політиці конфіденційності та з дотримання вимог законності і правомірності.
            </p>
            <p>
            <strong>Заперечення? </strong>Ознайомлення з цією Політикою конфіденційності допоможе Вам зрозуміти свої права та правомочності щодо конфіденційності. Якщо Ви не погоджуєтесь з нашою політикою та практикою, будь ласка, не використовуйте наші Послуги.
            </p>
            <p>
            <strong>РЕЗЮМЕ КЛЮЧОВИХ МОМЕНТІВ</strong>
            </p>
            <p>
            <strong>Які персональні дані ми опрацьовуємо? </strong>Коли Ви відвідуєте наші Сервіси, користуєтеся ними або переходите за посиланням на них, ми можемо опрацьовувати персональні дані на підставі Вашої згоди залежно від того, як Ви взаємодієте з нами, нашими послугами, який вибір робите у них, також іншими продуктами та функціями, які Ви використовуєте у процесі взаємодії з нашими послугами. Детальний перелік персональних даних, до яких ми можемо запитувати у Вас доступ, наведений у Розділі 1. «ЯКІ ПЕРСОНАЛЬНІ ДАНІ МИ ЗБИРАЄМО? ЯК МИ ОТРИМУЄМО ДОСТУП ДО ВАШИХ ДАНИХ?».
            </p>
            <p>
            <strong>Як ми отримуємо доступ до Ваших персональних даних? </strong>Доступ до Ваших персональних даних може включати заповнення клітинки позначкою під час реєстрації у нашому застосунку, обрання технічних налаштувань для Послуг чи поведінка, що чітко вказують на погодження Користувача із запропонованим опрацюванням персональних даних. Ми дотримуємось принципу прозорості, що передбачає, що будь-яка інформація та повідомлення щодо опрацювання персональних даних були доступними і зрозумілими, з використанням чітких і простих формулювань таким чином, щоб Користувач був обізнаний про факт і межі надання згоди.
            </p>
            <p>
            Ми збираємо дані, які Ви добровільно надаєте нам, коли виявляєте зацікавленість в отриманні інформації про нас або наші продукти та Послуги, коли Ви берете участь у діяльності в рамках Сервісів або іншим чином, коли Ви взаємодієте з нами.  За можливості ми  запитуємо доступ до даних користувача в контексті (через додаткову аутентифікацію), щоб Ви розуміли, навіщо нам потрібні дані.
            </p>
            <p>
            <strong>З якою метою та як ми опрацьовуємо Ваші персональні дані? </strong>Ми <strong> опрацьовуємо Ваші </strong>персональні дані для надання, покращення та адміністрування наших Послуг, спілкування з Вами, для безпеки та запобігання шахрайству, а також для дотримання законодавства. Ми можемо <strong>опрацьовувати Ваші </strong>персональні дані, якщо вважаємо, що це необхідно для Визначення тенденцій Використання, ефективності наших рекламних кампаній, а також для оцінки та покращення наших Послуг, продуктів, маркетингу. Ми також можемо опрацьовувати Ваші персональні дані  з Вашої згоди для інших законних цілей.
            </p>
            <p>
            <strong>Як ми зберігаємо Ваші персональні дані? </strong>Ми зберігаємо Ваші персональні дані Виключно з дотриманням вимог щодо їх цілісності та спеціального режиму доступу до них, що виключає можливість ознайомлення, розповсюдження та доступу до персональних даних користувачів прямо або опосередковано третіми особами. Ми опрацьовуємо персональні дані виключно у спосіб, що забезпечує відповідний рівень безпеки та конфіденційності персональних даних, у тому числі для запобігання несанкційованому доступу або використанню персональних даних, а також обладнання, необхідного для опрацювання та проти ненавмисної втрати, знищення чи завдання шкоди, із застосуванням відповідних технічних і організаційних інструментів.
            </p>
            <p>
            <strong>Чи надаємо (передаємо) ми доступ до Ваших персональних даних третім особам?</strong> Ми можемо ділитися персональними даними в конкретних ситуаціях і з конкретними третіми сторонами. Вичерпний перелік таких ситуацій Визначений Розділом 3. «КОЛИ І З КИМ МИ ДІЛИМОСЯ ВАШИМИ ПЕРСОНАЛЬНИМИ  ДАНИМИ<em>?</em>».
            </p>
            <p>
            <strong>Чи обробляємо ми будь-яку конфіденційну інформацію? </strong>Ми не обробляємо конфіденційну інформацію.
            </p>
            <p>
            <strong>Чи отримаємо ми будь-яку інформацію/дані від третіх осіб? </strong>Ми не отримуємо жодної інформації від третіх сторін.
            </p>
            <p>
            <strong>Які у Вас права? </strong>
            </p>
            <p>
            1) Ви маєте право<strong> </strong>на отримання інформації про Ваші персональні дані, які нами обробляються, цілі їх опрацювання, за можливості, період, протягом якого опрацьовують персональні дані (якщо це неможливо, — критерії визначення такого періоду), одержувачів персональних даних, логіку, що обумовлює будь-яке автоматизоване опрацювання персональних даних,  та отримання підтвердження і повідомлення про ті персональні дані, які Вас стосуються та підлягають опрацюванню, наслідки такого опрацювання, категорії відповідних персональних даних. Ви маєте право отримати копію своїх персональних даних, які знаходяться у процесі опрацювання. Ми надамо Вам інформацію у стислій, прозорій, доступній для розуміння формі, з використанням чітких і простих формулювань без необґрунтованої затримки та в будь-якому випадку протягом одного місяця з дати отримання запиту.
            </p>
            <p>
            2) Ви маєте право відкликати свою згоду на опрацювання Ваших персональних даних в будь-який момент. Відкликання згоди не впливає на законність опрацювання, що ґрунтувалося на згоді до її відкликання.
            </p>
            <p>
            3) Ви маєте право на обмеження опрацювання Ваших персональних даних або на заперечення проти опрацювання, а також право на мобільність даних. Ви маєте право на отримання Ваших персональних даних в структурованому, загальноприйнятому форматі, що легко зчитується.
            </p>
            <p>
            4) Ви маєте право надсилати запит щодо виправлення чи стирання Ваших персональних даних.
            </p>
            <p>
            5) Ви маєте «право бути забутим», якщо немає більше потреби в персональних даних для цілей, для яких їх збирали чи іншим чином опрацьовували; якщо утримання таких даних порушує Загальний регламент про захист даних (GDPR або законодавство Європейського Союзу чи держави-члена), яке поширюється на Вас. Ви маєте право відкликати свою згоду, на якій ґрунтується опрацювання.
            </p>
            <p>
            Залежно від того, де Ви географічно перебуваєте, відповідне законодавство Вашого місця перебування може надавати Вам певні права щодо Ваших персональних даних.
            </p>
            <p>
            <strong>Як я реалізую свої права? </strong>Найпростіший спосіб реалізувати свої права – зв’язатися з нами: <a href="mailto:contact@deadline.com.ua">contact@deadline.com.ua</a>. Ми розглянемо та застосуємо необхідні заходи за будь-яким запитом відповідно до чинного законодавства про захист персональних даних.
            </p>
            <p>
            Хочете дізнатися більше про те, що CLEVERVOL INC робить з будь-якими персональними даними, що ми збираємо?
            </p>
            <p>
            <strong>ЗМІСТ</strong>
            </p>
            <p>
            1. ЯКІ ПЕРСОНАЛЬНІ ДАНІ МИ ОПРАЦЬОВУЄМО? ЯК МИ ОТРИМУЄМО ДОСТУП ДО ВАШИХ ДАНИХ?
            </p>
            <p>
            2. З ЯКОЮ МЕТОЮ ТА ЯК МИ ОПРАЦЬОВУЄМО ПЕРСОНАЛЬНІ ДАНІ?
            </p>
            <p>
            3. КОЛИ І З КИМ МИ ДІЛИМОСЯ ВАШИМИ ПЕРСОНАЛЬНИМИ ДАНИМИ?
            </p>
            <p>
            4. ЯК ДОВГО МИ ЗБЕРІГАЄМО ПЕРСОНАЛЬНІ ДАНІ?
            </p>
            <p>
            5. ЧИ ЗБИРАЄМО ПЕРСОНАЛЬНІ ДАНІ  ВІД НЕПОВНОЛІТНІХ?
            </p>
            <p>
            6. ЯКІ ПРАВА НА КОНФІДЕНЦІЙНІСТЬ?
            </p>
            <p>
            7. КОНТРОЛЬ ДЛЯ ФУНКЦІЙ «НЕ ВІДСЛІДКОВУВАТИ»
            </p>
            <p>
            8. ЧИ МАЮТЬ ЖИТЕЛИ КАЛІФОРНІЇ СПЕЦІАЛЬНІ ПРАВА НА КОНФІДЕНЦІЙНІСТЬ?
            </p>
            <p>
            9. ЧИ  ОНОВЛЮЄМО МИ ЦЮ ПОЛІТИКУ?
            </p>
            <p>
            10. ЯК ВИ МОЖЕТЕ ЗВ’ЯЗАТИСЯ З НАМИ ЩОДО ЦІЄЇ ПОЛІТИКИ?
            </p>
            <p>
            11. ЯК ВИ МОЖЕТЕ ПЕРЕГЛЯНУТИ, ОНОВИТИ АБО ВИДАЛИТИ ДАНІ, ЩО МИ ЗБИРАЄМО ВІД ВАС?
            </p>
            <p>
            <strong>1. ЯКІ ПЕРСОНАЛЬНІ ДАНІ МИ ОПРАЦЬОВУЄМО? ЯК МИ ОТРИМУЄМО ДОСТУП ДО ВАШИХ  ДАНИХ?</strong>
            </p>
            <p>
            <strong><em>Коротко: ми збираємо та обробляємо персональні дані, які Ви нам надаєте. </em></strong>
            </p>
            <p>
            Ми збираємо дані, які Ви добровільно надаєте нам, коли виявляєте зацікавленість в отриманні інформації про нас або наші продукти та Послуги, коли Ви берете участь у діяльності в рамках Сервісів або іншим чином, коли Ви взаємодієте з нами.
            </p>
            <p>
            <strong>Персональні дані, надані Вами. </strong>Персональні дані, які ми опрацьовуємо, надаючи Вам Послуги, залежать від контексту Вашої взаємодії з нами, вибору, який Ви робите, а також функцій, які Ви використовуєте.
            </p>
            <p>
            Персональні дані, які ми збираємо та опрацьовуємо можуть включати наступне:
            </p>
            <ul>

            <li>Ваше ім'я та прізвище, електрону адресу чи номер телефону, графічну інформацію (картинки, фотографії), відео, аудіозаписи, музичні файли, інші файли та документи, можемо зберігати та опрацьовувати заходи з календаря, Ваші контакти та інші відомості (наприклад, відомості про перегляд інших сторінок в Інтернеті, звіти про помилки роботи програми, діагностику).
            </li>
            </ul>
            <p>
            При реєстрації у застосунку Deadline Expert ми запитуємо у Вас таку інформацію:
            </p>
            <ul>

            <li>Ім'я та Прізвище;</li>

            <li>Вашу електронну пошту або номер телефону;</li>

            <li>Пароль (ми не маємо доступ до Вашого паролю та не зберігаємо його).
            </li>
            </ul>
            <p>
            При використанні застосунку заповнюється самостійно Користувачем така інформація:
            </p>
            <ul>

            <li>назва компанії, в якій працює Користувач та посада Користувача;</li>

            <li>вибіркова інформація щодо специфіки результатів роботи Користувача у минулому.</li>
            </ul>
            <p>
            Для виконання своїх Послуг та надання Сервісів, деяку інформацію від Користувачів ми перевіряємо. Так, ми перевіряємо деяку інформацію (дані) про те, що Користувач є Журналістом.
            </p>
            <p>
            Ми запитуємо доступ лише до мінімально можливого обсягу даних, що є необхідним для ефективної реалізації Послуг та Сервісів. За можливості, ми запитуємо інформацію у відповідному контексті (через додаткову аутентифікацію), щоб Ви розуміли, навіщо нам потрібні дані.
            </p>
            <p>
            <strong>Конфіденційна інформація. </strong>Ми не опрацьовуємо конфіденційну інформацію.
            </p>
            <p>
            Всі персональні дані, які Ви нам надаєте, мають бути правдивими, повними та точними, і Ви повинні повідомляти нас про будь-які зміни такої інформації/даних.
            </p>
            <p>
            Деяку інформацію ми обробляємо автоматично, використовуючи математичні формули, та можемо оприлюднювати для Вас та інших Користувачів довідково.
            </p>
            <p>
            <strong>ЯК МИ ОТРИМУЄМО ДОСТУП ДО ВАШИХ ДАНИХ ? </strong>
            </p>
            <p>
            Доступ до Ваших персональних даних може включати обрання технічних налаштувань для Послуг чи поведінку, що чітко вказують на Ваше погодження із запропонованим опрацюванням персональних даних. Ми збираємо дані, які Ви добровільно надаєте нам, коли виявляєте зацікавленість в отриманні інформації про нас або наші Послуги, коли Ви берете участь у діяльності в рамках Сервісів або іншим чином, коли Ви взаємодієте з нами.  За можливості ми  запитуємо доступ до даних у відповідному контексті (через додаткову аутентифікацію), щоб Ви розуміли, навіщо нам потрібні дані.
            </p>
            <p>
            <strong>2. З ЯКОЮ МЕТОЮ ТА ЯК МИ ОПРАЦЬОВУЄМО ПЕРСОНАЛЬНІ ДАНІ?</strong>
            </p>
            <p>
            <strong><em>Скорочено: ми використовуємо Ваші персональні дані для надання, покращення та адміністрування наших Послуг, спілкування з Вами, для безпеки та запобігання шахрайству, а також для дотримання законодавства. Ми також можемо опрацьовувати Ваші персональні дані  з Вашої згоди для інших цілей.</em></strong>
            </p>
            <p>
            <strong>Ми опрацьовуємо Ваші персональні дані з різних причин, залежно від того, як Ви взаємодієте з нашими Послугами, зокрема:</strong>
            </p>
            <ul>

            <li><strong>для оцінки та покращення наших Сервісів чи маркетингу. </strong>Ми можемо опрацьовувати Ваші<strong><em> </em></strong>персональні дані, якщо вважаємо, що це необхідно для визначення тенденцій використання, ефективності наших рекламних кампаній, а також для оцінки та покращення наших Послуг, маркетингу та Вашого досвіду.
            </li>
            </ul>
            <p>
            Якщо нам потрібно буде надалі опрацьовувати персональні дані для іншої цілі, ніж та, для якої персональні дані було отримано, ми надамо Вам до початку такого подальшого опрацювання інформацію про таку іншу ціль і будь-яку належну детальну інформацію, що вказана у Загальному регламенті про захист даних (GDPR).
            </p>
            <p>
              <strong>Чи оновлюємо ми Ваші персональні дані? </strong>
            </p>
            <p>
            Ми вживаємо усіх відповідних заходів для оновлення Ваших даних для того, щоб забезпечити, що неточні персональні дані, зважаючи на цілі їхнього опрацювання, було стерто чи виправлено без затримки. Ви маєте право звернутись до нас для виправлення Ваших персональних даних та право заповнити незаповнені персональні дані, в тому числі, надавши додаткову заяву у письмовому вигляді.
            </p>
            <p>
              <strong>Які підстави для обмеження персональних даних? </strong>
            </p>
            <p>
            Ви маєте право звернутись до нас з заявою щодо обмеження опрацювання Ваших персональних даних у разі настання таких обставин: точність персональних даних оскаржується Вами, протягом періоду часу, що надає нам можливість перевірити таку Вашу заяву;  опрацювання є незаконним та Ви виступаєте проти стирання персональних даних і натомість надсилаєте запит на обмеження їх Використання.
            </p>
            <p>
            Ми ведемо записи опрацювання персональних даних згідно з Загальним регламентом про захист даних (GDPR).
            </p>
            <p>
            <strong>3. КОЛИ І З КИМ МИ ДІЛИМОСЯ ВАШИМИ ПЕРСОНАЛЬНИМИ  ДАНИМИ?</strong>
            </p>
            <p>
            <strong><em>Скорочено: ми можемо ділитися персональними даними в конкретних ситуаціях, описаних у цьому розділі, та/або з наступними третіми сторонами.</em></strong>
            </p>
            <p>
            Нам може знадобитися надання Ваших персональних даних в таких ситуаціях:
            </p>
            <ul>

            <li><strong>Маркетингова мета (у т.ч. персоналізація та реклама).</strong> Ми збираємо інформацію (дані) і також можемо передавати їх третім особам чи стороннім Сервісам (наприклад, щоб Ви мали можливість отримувати інформацію у вигляді розсилки на електрону адресу або на вказаний номер телефону).
            </li>
            <li><strong>Організаційні процеси. </strong>Ми можемо поділитися або передати інформацію (дані) у зв’язку з будь-яким злиттям, продажем активів компанії, фінансуванням або придбанням всієї або частини нашої організаційної діяльності іншій компанії або під час переговорів про це. Ми здійснюємо передавання Ваших персональних даних фізичним і юридичним особам, які дотримуються умов Загального регламенту  про захист даних (GDPR).
            </li>
            </ul>
            <p>
            Інформація (дані), яку ми збираємо та опрацьовуємо передається у зашифрованому вигляді, крім тієї, що передається третім особам з метою розсилок (маркетинговою метою).
            </p>
            <p>
            <strong>4. ЯК ДОВГО МИ ЗБЕРІГАЄМО ПЕРСОНАЛЬНІ ДАНІ?</strong>
            </p>
            <p>
            <strong><em>Скорочено: Ми вживаємо всіх необхідних заходів для того, щоб період, протягом якого зберігаються персональні дані, був скорочений до абсолютного мінімуму. Критеріями визначення періоду зберігання персональних даних Користувача є досягнення мети (цілей), визначених у Розділі 2. «З ЯКОЮ МЕТОЮ ТА ЯК МИ ОПРАЦЬОВУЄМО ПЕРСОНАЛЬНІ ДАНІ?». </em></strong>
            </p>
            <h5>Ми зберігаємо Ваші персональні дані стільки, скільки необхідно для досягнення цілей, зазначених у цій Політиці конфіденційності.</h5>


            <p>
            Ви чи будь-який Користувач має право на видалення своїх персональних даних та припинення їхнього опрацювання («право бути забутим»), якщо персональні дані більше не є потрібними щодо цілей, для яких ми їх збираємо або іншим чином опрацьовуємо, якщо суб’єкт даних відкликав свою згоду або заперечує проти опрацювання його або її персональних даних, та якщо немає іншої законної підстави для опрацювання, або якщо опрацювання його чи її персональних даних іншим чином не відповідає Загальному регламенту про захист даних (GDPR). У випадку отримання запиту на стирання персональних даних та у випадку надання Ваших персональних даних третім особам (відповідно до Розділу 3.) ми вживаємо відповідних заходів, у тому числі, технічних заходів, для інформування таких третіх осіб, які опрацьовують персональні дані, про те, що Вами був направлений запит на стирання будь-яких посилань на такі персональні дані, їхні копії чи відтворення.
            </p>
            <p>
            Ми зберігатимемо Ваші персональні дані лише стільки часу, скільки це необхідно для цілей, зазначених у цій Політиці конфіденційності, якщо довший період зберігання не вимагається або не дозволений законом (наприклад, вимоги щодо оподаткування, бухгалтерського обліку чи інші законодавчі вимоги).
            </p>
            <p>
            Якщо у нас немає поточної законної ділової потреби в опрацюванні<strong><em> </em></strong>Ваших персональних даних, ми видалимо або анонімізуємо таку інформацію.
            </p>
            <p>
            <strong>5. ЧИ ЗБИРАЄМО ПЕРСОНАЛЬНІ ДАНІ ВІД НЕПОВНОЛІТНІХ?</strong>
            </p>
            <p>
            <strong><em>Скорочено: ми свідомо не збираємо персональні дані від дітей віком до 18 років і не продаємо їх.</em></strong>
            </p>
            <p>
            Ми свідомо не збираємо, не опрацьовуємо персональні дані від дітей віком до 18 років і не передаємо їх. Якщо ми дізнаємося, що були зібрані персональні дані від Користувачів віком до 18 років, ми деактивуємо обліковий запис та здійснимо заходи для негайного видалення таких даних з наших записів. Якщо Вам стане відомо про будь-які дані, які ми помилково зібрали від дітей віком до 18 років, зв’яжіться з нами на <a href="mailto:contact@deadline.com.ua">contact@deadline.com.ua</a>.
            </p>
            <p>
            Право на видалення персональних даних зберігається в тому числі, якщо Користувач надав свою згоду, будучи особою до 18 років, та не був повністю обізнаним про ризики, пов’язані з опрацюванням, а пізніше захотів видалити такі персональні дані. Такий Користувач в праві звернутись з проханням про видалення даних, незважаючи на той факт, що його вік на момент звернення становить більше 18 років.
            </p>
            <p>
            <strong>6. ЯКІ ПРАВА НА КОНФІДЕНЦІЙНІСТЬ?</strong>
            </p>
            <p>
            <strong><em>Скорочено: Ви можете будь-коли переглянути, змінити або припинити дію свого облікового запису.</em></strong>
            </p>
            <p>
            Якщо Ви перебуваєте в ЕЕА або Великобританії і вважаєте, що ми незаконно обробляємо Ваші персональні дані, Ви також маєте право подати скаргу до місцевого органу нагляду за захистом даних. Їхні контактні дані можна знайти тут: <a href="https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm">https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm</a>.
            </p>
            <p>
            Якщо Ви перебуваєте в Швейцарії, контактні дані органів із захисту даних доступні тут: <a href="https://www.edoeb.admin.ch/edoeb/en/home.html">https://www.edoeb.admin.ch/edoeb/en/home.html</a>.
            </p>
            <p>
            <strong><span style={{textDecoration: 'underline'}}>Відкликання Вашої згоди:</span> </strong>якщо ми покладаємося на Вашу згоду на обробку персональних даних, яка може бути явною та/або неявною згодою залежно від чинного законодавства, Ви маєте право відкликати свою згоду в будь-який час. Ви можете відкликати свою згоду в будь-який час, зв’язавшись з нами за контактними даними, наведеними в розділі «ЯК ВИ МОЖЕТЕ ЗВ’ЯЗАТИСЯ З НАМИ ЩОДО ЦІЄЇ ПОЛІТИКИ?» нижче.
            </p>
            <p>
            Однак зверніть увагу, що це не вплине на законність обробки до відкликання, а також, якщо це дозволено застосованим законодавством, це не вплине на обробку Ваших персональних даних, яка здійснюється на підставі законної обробки.
            </p>
            <p>
            Якщо у Вас є запитання чи коментарі щодо прав на конфіденційність, Ви можете надіслати нам електронний лист на адресу <a href="mailto:contact@deadline.com.ua">contact@deadline.com.ua</a>.
            </p>
            <p>
            <strong>7. КОНТРОЛЬ ДЛЯ ФУНКЦІЙ «НЕ ВІДСЛІДКОВУВАТИ»</strong>
            </p>
            <p>
            Більшість веб-браузерів, а також деякі мобільні операційні системи та мобільні додатки включають функцію або налаштування Do-Not-Track ("DNT"), які можна активувати, щоб повідомити, що Ваші налаштування конфіденційності не мають даних про активність перегляду в Інтернеті. На даному етапі не був остаточно розроблений єдиний технологічний стандарт розпізнавання та реалізації сигналів DNT. Таким чином, наразі ми не відповідаємо на сигнали браузера DNT або будь-який інший механізм, який автоматично повідомляє про ваш вибір не відслідковуватись в Інтернеті. Якщо буде прийнятий стандарт для відстеження в Інтернеті, якого ми повинні дотримуватися в майбутньому, ми повідомимо Вас про цю практику в переглянутій версії цієї Політики конфіденційності.
            </p>
            <p>
            <strong>8. ЧИ МАЮТЬ ЖИТЕЛИ КАЛІФОРНІЇ СПЕЦІАЛЬНІ ПРАВА НА КОНФІДЕНЦІЙНІСТЬ?</strong>
            </p>
            <p>
            <strong><em>Скорочено: так, якщо Ви проживаєте в Каліфорнії, то Вам надаються певні права щодо доступу до ваших персональних даних. </em></strong>
            </p>
            <p>
            Розділ 1798.83 Цивільного кодексу Каліфорнії, також відомий як закон «Shine The Light», дозволяє нашим користувачам, які є жителями Каліфорнії, запитувати та отримувати від нас раз на рік безкоштовно інформацію про категорії персональної інформації/даних (якщо така є), розкриті третім сторонам для цілей прямого маркетингу, а також імена та адреси всіх третіх сторін, з якими ми поділилися персональними даними протягом попереднього календарного року. Якщо Ви проживаєте в Каліфорнії та бажаєте зробити такий запит, надішліть нам письмовий запит, Використовуючи контактну інформацію, наведену нижче.
            </p>
            <p>
            <strong>9. ЧИ ОНОВЛЮЄМО МИ  ЦЮ ПОЛІТИКУ?</strong>
            </p>
            <p>
            <strong><em>Скорочено: так, ми будемо оновлювати цю Політику, якщо це необхідно, щоб відповідати вимогам законодавства.</em></strong>
            </p>
            <p>
            Ми можемо час від часу оновлювати цю Політику. Оновлену версію буде вказано оновленою із приміткою і оновлена ​​версія набуде чинності, щойно вона стане доступною. Якщо ми внесемо суттєві зміни до цієї Політики конфіденційності, ми можемо повідомити Вас, розмістивши повідомлення про такі зміни на видному місці, або безпосередньо надіславши таке сповіщення. Ми рекомендуємо також самостійно переглядати Політику, щоб бути в курсі того, як ми захищаємо інформацію.
            </p>
            <p>
            <strong>10. ЯК ВИ МОЖЕТЕ ЗВ’ЯЗАТИСЯ З НАМИ ЩОДО ЦІЄЇ ПОЛІТИКИ?</strong>
            </p>
            <p>
            Якщо у Вас виникли запитання чи зауваження щодо цієї Політики, Ви можете надіслати нам електронний лист на адресу <a href="mailto:contact@deadline.com.ua">contact@deadline.com.ua</a>.
            </p>
            <p>
            <strong>11. ЯК ВИ МОЖЕТЕ ПЕРЕГЛЯНУТИ, ОНОВИТИ АБО ВИДАЛИТИ ДАНІ, ЩО МИ ЗБИРАЄМО ВІД ВАС?</strong>
            </p>
            <p>
            Ми вживаємо всіх відповідних заходів для забезпечення виправлення або видалення неточних персональних даних. Відповідно до чинного законодавства України, Вашої країни, Ви можете володіти правом вимагати доступу до персональних даних, які ми збираємо від вас, змінювати цю інформацію або видаляти її за певних обставин. Щоб надіслати запит на оновлення або видалення ваших персональних даних, напишіть лист на електронну адресу <a href="mailto:contact@deadline.com.ua">contact@deadline.com.ua</a>.
            </p>
            <p>
            <strong>Дякуємо за співпрацю.</strong>
            </p>
          </div>
        </div>
      </Container>
      <div className="s-100"></div>
      <Footer />
    </>
  )
}

export default PrivacyUA
