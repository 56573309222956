const enp = [
  {
    key: 0,
    title: 'Starter',
    subtitle: 'For acquaintance Ukraine',
    desc: 'Standard service',
    price1: 5,
    price2: 12,
    price3: 42,
    link1: 'https://buy.stripe.com/test_00g9Ex7f00wngSccMM',
    link2: 'https://buy.stripe.com/test_5kA4kd9n83Iz59ubIJ',
    link3: 'https://buy.stripe.com/test_28obMF8j4bb1eK4002',
    colors: ['#78D7FF', '#4F9FC0', '#D8F3FF', '#FFFBFB', '#00B3FE', '#6CC7ED'],
    list: ['Up to 5', '🚫', '🚫', '🚫', '🚫', '🚫', '🚫', '✅']
  },
  {
    key: 1,
    title: 'Beginner',
    subtitle: 'For individual experts',
    desc: 'Standard service',
    price1: 20,
    price2: 48,
    price3: 170,
    link1: 'https://buy.stripe.com/test_7sI3g98j41ArcBWaEH',
    link2: 'https://buy.stripe.com/test_aEU03X42O0wndG07sw',
    link3: 'https://buy.stripe.com/test_8wMeYR56Send1XicMR',
    colors: ['#78D7FF', '#4F9FC0', '#D8F3FF', '#FFFBFB', '#00B3FE', '#6CC7ED'],
    list: ['Up to 10', '🚫', '🚫', '✅', '🚫', '🚫', 'Email', '✅'],
  },
  {
    key: 2,
    title: 'Expert',
    subtitle: 'Active PR policy',
    desc: 'Standard service',
    price1: 65,
    price2: 156,
    price3: 545,
    link1: 'https://buy.stripe.com/test_00g9Ex2YK6ULcBW3ci',
    link2: 'https://buy.stripe.com/test_cN24kd56S1ArdG07sz',
    link3: 'https://buy.stripe.com/test_fZecQJ56S1Ar31mfZ6',
    colors: ['#5ECFFF', '#235B73', '#D8F3FF', '#EBF9FF', '#0C8EC5', '#168BBD'],
    list: ['Up to 15', '✅', '✅', '✅', '✅', 'Email', '1 per   /mo  ', '✅'],
  },
  {
    key: 3,
    title: 'Personal',
    subtitle: 'Virtual assistant',
    desc: "We'll do it for you",
    price1: 370,
    price2: 890,
    price3: 3110,
    link1: 'https://buy.stripe.com/test_7sI3g92YKfrh45q14d',
    link2: 'https://buy.stripe.com/test_6oEeYRgPAbb1cBW5ku',
    link3: 'https://buy.stripe.com/test_7sIbMF1UGbb1cBWbIT',
    colors: ['#BACE40', '#798434', '#EFFEC6', '#FFFBFB', '#A9C116', '#ABBD3E'],
    list: ['Unlim', '✅', '✅', '✅', '✅', 'Email, Zoom', '2 per   /mo  ', '✅']
  }
]

export default enp
